import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { faCopy, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Card, CardContent, CardHeader, Grid2 as Grid } from "@mui/material";
import moment from "moment";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/adventure_time.css";
import {
  Alert as AlertDismissible,
  Button,
  ModalPopUp,
} from "../../../components/Common";
import { Date_Time_Format, Send_Api_Date_Format } from "../../../config";
import {
  getBackendLogData,
  repostData,
} from "../../../Services/executionService";
import { CustomGrid } from "../../CustomTable";
import { formatDate } from "../../Utility/DatePickerFunctions";
import { IsConfirm } from "../../Utility/ModalPopUp";
import StandardDatePicker from "../../Utility/StandardDatePicker";

class BackendData extends React.Component {
  constructor(props) {
    super(props);
    const displayTImeZoneOption = require("../../../config/timezone.json");
    this.state = {
      BackendRecordsData: [],
      displayTImeZoneOption: displayTImeZoneOption,
      displayTimeZone: {
        label: "(GMT-8) Pacific Time (US & Canada)",
        value: "6",
      },
      displayTimeZoneOffset: "-08:00",
      loading: true,
      perPageCount: 10,
      totalPages: 10,
      currentPage: 0,
      variant: "",
      rowData: null,
      show: false,
      searchText: "",
      logStartDate: new Date(),
      logEndDate: new Date(),
      dateErrorMsg: false,
      clipmsg: "",
      alertStatus: false,
      isRepostConfirm: false,
    };
  }

  componentDidMount() {
    this.props.action("Backend Data");
    let filters = this.getinitialData();
    this.getBackendRecordsData(filters);
  }
  searchResultsByText = (event) => {
    let text = event.target.value;
    this.setState(
      (preState) => {
        return { searchText: text };
      },
      () => {}
    );
  };
  getinitialData = () => {
    let initialdata = {
      page: this.state.currentPage,
      pageSize: this.state.perPageCount,
      displayTimeZone: this.state.displayTimeZoneOffset,
      startDate: moment(this.state.logStartDate).format(Send_Api_Date_Format),
      endDate: moment(this.state.logEndDate).format(Send_Api_Date_Format),
      search: this.state.searchText,
    };
    return initialdata;
  };

  onChangeRecords = (perPageCount) => {
    this.setState(
      {
        perPageCount: perPageCount.pageSize,
        currentPage: perPageCount.page,
        loading: true,
      },
      () => {
        let filters = this.getinitialData();
        this.getBackendRecordsData(filters);
      }
    );
  };
  handlefilterApplyChange = () => {
    this.setState(
      {
        currentPage: 0,
        loading: true,
      },
      () => {
        let filters = this.getinitialData();
        this.getBackendRecordsData(filters);
      }
    );
  };
  repost = () => {
    this.setState({ isRepostConfirm: true });
  };
  handleRepostConfirm = (e, rdata) => {
    let repostdata = rdata;
    if (e !== false) {
      repostData(repostdata)
        .then((res) => {
          this.setState({
            alertMsg: "Result Reposted Sucessfully",
            variant: "success",
            alertStatus: true,
            isRepostConfirm: false,
          });
        })
        .catch((err) => {
          console.log("initial err", err);
        });
    }
    this.setState({ isRepostConfirm: false });
  };
  getBackendRecordsData = (filters) => {
    getBackendLogData(filters)
      .then((res) => {
        this.setState({
          BackendRecordsData: [],
        });
        console.log("BackendRecordsData", res.data);
        const totalPages = Math.ceil(
          res.data.totalResults / this.state.perPageCount
        );
        if (!res.data.resultAuditLogs.length) {
          this.setState({
            alertMsg: this.props.t("There are no records to show."),
            variant: "success",
            alertStatus: true,
          });
        } else {
          this.setState({
            alertMsg: "",
            variant: "",
            alertStatus: false,
          });
        }
        this.setState(
          {
            BackendRecordsData: res.data.resultAuditLogs,
            totalResults: res.data.totalResults,
            totalPages: totalPages,
            loading: false,
          },
          () => {}
        );
      })
      .catch((err) => {
        console.log("initial err", err);
      });
  };
  onChangeTimeZone = (option) => {
    const timearray = this.state.displayTImeZoneOption;
    let filterOffset =
      timearray != undefined &&
      timearray.filter(function (timearray) {
        return timearray.value === option.value;
      });
    console.log(filterOffset);

    this.setState(
      {
        displayTimeZone: option,
        displayTimeZoneOffset: filterOffset[0].offset,
      },
      () => {}
    );
  };
  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  setStartDatepickerValue = (selectedDate) => {
    let startTime = selectedDate;
    let endTime = this.state.logEndDate;

    this.setState(
      {
        logStartDate: selectedDate,
      },
      () => {
        if (startTime.setHours(0, 0, 0, 0) > endTime.setHours(0, 0, 0, 0)) {
          this.setState({
            dateErrorMsg: true,
          });
        } else {
          this.setState({
            dateErrorMsg: false,
          });
        }
      }
    );
  };

  setEndDatepickerValue = (selectedDate) => {
    let startTime = this.state.logStartDate;
    let endTime = selectedDate;
    this.setState(
      {
        logEndDate: selectedDate,
      },
      () => {
        if (startTime.setHours(0, 0, 0, 0) > endTime.setHours(0, 0, 0, 0)) {
          this.setState({
            dateErrorMsg: true,
          });
        } else {
          this.setState({
            dateErrorMsg: false,
          });
        }
      }
    );
  };
  rowModalData = (rowData) => {
    this.setState({
      show: true,
      rowData: rowData,
      uploadedResult: JSON.parse(rowData),
    });
  };

  hideModal = () => {
    this.setState({
      show: false,
      clipmsg: "",
    });
  };

  handleCopy = (el) => {
    const data = el.props.data;
    let textArea = document.createElement("textarea");
    textArea.width = "1px";
    textArea.height = "1px";
    textArea.background = "transparents";
    textArea.value = data;
    document.body.append(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    this.setState({
      clipmsg: "Text Copied to Clipboard",
    });
  };

  getBackendRecordsDataRows = (data) => {
    let row = data.map((logdata, index) => {
      return {
        callId: logdata.id,
        payload: logdata.uploadedResult,
        uploaddate:
          logdata.uploadedAt != null
            ? moment(logdata.uploadedAt)
                .utcOffset(this.state.displayTimeZoneOffset)
                .format(Date_Time_Format)
            : "-",
        runId:
          logdata.runId !== null ? (
            <span>{`RunId: ${logdata.runId}`}</span>
          ) : (
            <span>NA</span>
          ),
      };
    });
    return row;
  };

  render() {
    let JSONPrettyMon = require("react-json-pretty/themes/adventure_time.css");
    let { t } = this.props;
    let rows = this.getBackendRecordsDataRows(this.state.BackendRecordsData);
    let columns = [
      {
        headerName: `${t("Upload Date")}`,
        field: "uploaddate",
        flex: 0.7,
        renderCell: (params) =>
          params.value != null
            ? moment(params.value)
                .utcOffset(this.state.displayTimeZoneOffset)
                .format(Date_Time_Format)
            : "-",
        sortable: false,
      },
      {
        headerName: `${t("Payload")}`,
        field: "payload",
        classes: "payloaddata",
        flex: 2,
        sortable: false,
        renderCell: (params) => {
          return (
            <pre
              onClick={() => this.rowModalData(params.value)}
              style={{ cursor: "pointer" }}
            >
              {params.value}
            </pre>
          );
        },
      },
      {
        headerName: `${t("DEBUG_INFO")}`,
        field: "runId",
        flex: 1,
        sortable: false,
        renderCell: (params) => params.value,
      },
    ];

    return (
      <div className="w-1200">
        <Grid container className="participants-page">
          <Grid item size={12}>
            <div className="">
              <Card>
                <CardHeader
                  title={
                    <Grid container>
                      <div className="col-lg-4"> {t("Search")}</div>
                      <div className="col-lg-3"> {t("Display Options")}</div>
                      <div className="col-lg-4">
                        {" "}
                        {t("Filter by Date Range")}
                      </div>
                    </Grid>
                  }
                ></CardHeader>
                <CardContent className="pt-2 resultsearchindex">
                  <Grid container>
                    <div className="position-relative col-lg-4">
                      <input
                        type="text"
                        placeholder={t("Search")}
                        className="form-control"
                        onChange={this.searchResultsByText}
                      />
                    </div>
                    <div className="position-relative col-lg-3">
                      <Select
                        name="location"
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.displayTImeZoneOption}
                        isClearable={false}
                        value={this.state.displayTimeZone}
                        placeholder={t("Select Timezone")}
                        onChange={(option) => this.onChangeTimeZone(option)}
                      />
                    </div>
                    <div className="position-relative col-lg-4">
                      <Grid container>
                        <div className="position-relative col-lg-6">
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            placeholderText="Start Date"
                            autoComplete="off"
                            className="form-control w-100 "
                            name="logStartDate"
                            onChange={(selectedDate) => {
                              this.setStartDatepickerValue(selectedDate);
                            }}
                            selected={this.state.logStartDate}
                            label={t("From")}
                          />
                        </div>
                        <div className="position-relative col-lg-6">
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            placeholderText="Start Date"
                            autoComplete="off"
                            className="form-control w-100 "
                            name="logEndDate"
                            onChange={(selectedDate) => {
                              this.setEndDatepickerValue(selectedDate);
                            }}
                            selected={this.state.logEndDate}
                            label={t("To")}
                          />
                        </div>
                      </Grid>
                      {this.state.dateErrorMsg ? (
                        <span className="input-feedback">
                          End Date must be greater than Start Date
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="position-relative col-lg-1">
                      <label></label>
                      <Button
                        variant="primary"
                        ref=""
                        disabled={this.state.dateErrorMsg}
                        onClick={(e) => this.handlefilterApplyChange()}
                        className="text-uppercase btn-sm-text float-right"
                      >
                        {t("Apply")}
                      </Button>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item size={12}>
            <Card>
              <div className="table_wrapper participant-table custom_table_container app-table backenddata-module-table">
                <ModalPopUp
                  show={this.state.show}
                  onHide={this.hideModal}
                  title="Payload Details"
                >
                  <Grid item size={12}>
                    <AlertDismissible
                      msg={this.state.alertMsg}
                      variantType={this.state.variant}
                      show={this.state.alertStatus}
                      close={this.handleAlertClose}
                    ></AlertDismissible>
                  </Grid>
                  <IsConfirm
                    show={this.state.isRepostConfirm}
                    onOkay={(e) =>
                      this.handleRepostConfirm(e, this.state.rowData)
                    }
                    t={this.props.t}
                  >
                    {t("Do you really want to repost result?")}
                  </IsConfirm>
                  <div className="clearfix">
                    <div className="clearfix">
                      <a
                        className="rounded-0 float-left btn btn-primary btn-sm text-white mb-1"
                        onClick={() => this.handleCopy(this.sample)}
                      >
                        <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                        {t("Copy")}
                      </a>
                      <span className="ml-2">{this.state.clipmsg}</span>
                      <a
                        className="rounded-0 float-right btn btn-primary btn-sm text-white mb-1"
                        onClick={() => this.repost()}
                      >
                        <FontAwesomeIcon icon={faReply}></FontAwesomeIcon>
                        {t("Repost")}
                      </a>
                    </div>
                    <pre>
                      <JSONPretty
                        id="json-pretty"
                        className="participant-json-pretty"
                        theme={JSONPrettyMon}
                        ref={(r) => (this.sample = r)}
                        data={
                          this.state.rowData != null
                            ? this.state.rowData
                            : "NO Data Found"
                        }
                      ></JSONPretty>
                    </pre>
                  </div>

                  <div>
                    <Button variant="secondary" onClick={this.hideModal}>
                      Close
                    </Button>
                  </div>
                </ModalPopUp>
                <CustomGrid
                  columns={columns}
                  data={rows}
                  currentPage={this.state.currentPage}
                  perPageCount={this.state.perPageCount}
                  totalPages={this.state.totalPages}
                  isServerPagination={true}
                  getRowId={(row) => row.callId}
                  loading={this.state.loading}
                  onChangeRecords={this.onChangeRecords}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(connect()(BackendData));
