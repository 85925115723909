import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { Card, CardContent, CardHeader, Grid2 as Grid } from "@mui/material";
import moment from "moment";
import * as FileSaver from "file-saver";
import { sortByLabel } from "../../../Helpers/index";
import {
  IncompleteCallExport,
  InterviewResultsReport,
} from "../../../Services/executionService";
import { getTenantProjects } from "../../../Services/userServices";
import dataoptions from "../../../assets/icon/dataoptions.png";
import dataoptionsw from "../../../assets/icon/dataoptionsw.png";
import export_icon from "../../../assets/icon/export_icon.png";
import export_iconw from "../../../assets/icon/export_iconw.png";
import { Alert as AlertDismissible, Button } from "../../../components/Common";
import { Date_Time_Format, Send_Api_Date_Format } from "../../../config";
import { CustomGrid } from "../../CustomTable";
import { formatDate } from "../../Utility/DatePickerFunctions";
import FullScreenLoader from "../../Utility/FullScreenLoader";
import StandardDatePicker from "../../Utility/StandardDatePicker";

class IncompleteCalls extends Component {
  constructor(props) {
    super(props);
    const displayTImeZoneOption = require("../../../config/timezone.json");
    const telcoDropdown = require("../../../config/telco.json");
    const { t } = props;
    const selectRef = null;
    this.state = {
      displayTImeZoneOption: displayTImeZoneOption,
      accountsDetails: [],
      reportStartDate: new Date(),
      reportEndDate: new Date(),
      telcoDropdown: telcoDropdown,
      displayTimeZoneOffset: "-08:00",
      loading: true,
      perPageCount: 10,
      totalPages: 10,
      currentPage: 0,
      risklevel: ["Incomplete", "DC"],
      resultdata: [],
      selectedTelcoId: "",
      minDuration: 0,
      maxDuration: 0,
      durationOption: { value: "", label: "" },
      displayTimeZone: {
        label: "(GMT-8) Pacific Time (US & Canada)",
        value: "6",
      },
      sortOrder: {},
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isResultDownload: false,
      totalResults: 0,
    };
  }

  initialdata = () => {
    let initialdata = {
      projectId: this.state.selectedProjectId,
      tenantId: this.state.selectedAcccountId,
      displayTimeZone: this.state.displayTimeZoneOffset,
      startDate: moment(this.state.reportStartDate).format(
        Send_Api_Date_Format
      ),
      endDate: moment(this.state.reportEndDate).format(Send_Api_Date_Format),
      search: this.state.searchText,
      sortBy: this.state.sortOrder,
      page: this.state.currentPage,
      pageSize: this.state.perPageCount,
      riskLevels: this.state.risklevel,
      telcoProvider: this.state.selectedTelcoId,
      minDuration: this.state.minDuration,
      maxDuration: this.state.maxDuration,
      exportResultsReport: true,
    };
    return initialdata;
  };

  handlefilterApplyChange = () => {
    this.setState(
      {
        currentPage: 0,
        loading: true,
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
      }
    );
  };

  searchResultsByText = (event) => {
    let text = event.target.value;
    this.setState((preState) => {
      return { searchText: text };
    });
  };

  callProjectResultApi = (initialdata) => {
    this.setState(
      {
        alertStatus: false,
        resultdata: [],
        totalResults: 0,
      },
      () => {
        let initialdata = this.initialdata();
        InterviewResultsReport(initialdata)
          .then((res) => {
            console.log("InterviewResultsReport", res.data);
            console.log("this 1", this);
            if (!res.data.results.length) {
              this.setState({
                alertMsg: this.props.t("There are no results to show."),
                variant: "success",
                alertStatus: true,
                loading: false,
              });
            }
            this.setState(
              {
                resultdata: res.data.results,
                totalResults: res.data.totalResults,
                totalPages: Math.ceil(
                  res.data.totalResults / this.state.perPageCount
                ),
                loading: false,
              },
              () => {}
            );
          })
          .catch((err) => {
            console.log("initial err", err);
            let errMsg = err?.response?.data?.message;
            this.setState({
              alertMsg: errMsg,
              variant: "danger",
              alertStatus: true,
              loading: false,
            });
          });
      }
    );
  };

  exportIncompleteCalls = (allRecords) => {
    this.setState({
      isResultDownload: true,
    });
    let data = this.initialdata();
    if (allRecords) data.allRecords = allRecords;

    IncompleteCallExport(data)
      .then((res) => {
        console.log("export incomplete calls", res);
        const blob = new Blob([res.data], { type: "text/csv; charset=utf-8" });
        FileSaver.saveAs(blob, "incompletecalls.xlsx");
        this.setState({
          isResultDownload: false,
        });
      })
      .catch((err) => {
        console.log("error in export incomplete calls", err);
        if (err?.response?.status === 500) {
          let errMsg = err?.response?.data?.message;
          this.setState({
            alertMsg: errMsg,
            variant: "danger",
            alertStatus: true,
            isResultDownload: false,
          });
        } else {
          let errMsg = err?.response?.data?.message;
          const textDecoder = new TextDecoder();
          const decode = textDecoder.decode(
            new Uint8Array(err?.response?.data)
          );
          const object = JSON.parse(decode);
          this.setState({
            alertMsg:
              object?.status === 409
                ? object?.message
                : errMsg || object?.message,
            variant: "danger",
            alertStatus: true,
            isResultDownload: false,
          });
        }
      });
  };
  componentDidMount() {
    this.props.action("Incomplete Calls");
    getTenantProjects()
      .then((res) => {
        let tenantDropDown = res.data
          .map((option) => {
            return {
              value: option.tenantId,
              label: option.tenantName,
            };
          })
          .sort(sortByLabel);
        this.setState(
          {
            accountsDetails: res.data,
            tenantDropOption: tenantDropDown,
          },
          () => {
            let datafiltered = this.initialdata();

            this.callProjectResultApi(datafiltered);
          }
        );
      })
      .catch((err) => {});
  }

  getOnchangeAccount = (operatingTenant, data) => {
    let selectedAccount = data.filter((item) => {
      return item.tenantId == operatingTenant;
    });
    console.log(selectedAccount[0]);
    if (
      selectedAccount[0] != undefined &&
      selectedAccount[0].projects != null
    ) {
      let projectDropDown = selectedAccount[0].projects
        .map((option) => {
          return {
            value: option.projectId,
            label: option.name,
          };
        })
        .sort(sortByLabel);
      return projectDropDown;
    }
  };

  onChangeTenant = (e) => {
    let projectDropDown =
      e !== null
        ? this.getOnchangeAccount(e.value, this.state.accountsDetails)
        : [];
    this.setState(
      {
        projectDropDownOption: projectDropDown,
        selectedAcccountId: e !== null ? e.value : "",
        selectedAcccountLabel: e !== null ? e.label : "",
        selectedProjectId: "",
        selectedProjectLabel: "",
        currentPage: 0,
        totalResults: 0,
        totalPages: 0,
        alertMsg: "",
        variant: "",
        alertStatus: false,
        loading: true,
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
      }
    );
  };

  onChangeTenantProject = (e) => {
    this.setState(
      {
        selectedProjectId: e !== null ? e.value : "",
        selectedProjectLabel: e !== null ? e.label : "",
        currentPage: 0,
        loading: true,
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
      }
    );
  };

  setStartDatepickerValue = (selectedDate) => {
    let startTime = selectedDate;
    let endTime = this.state.reportEndDate;

    this.setState(
      {
        reportStartDate: selectedDate,
      },
      () => {
        if (startTime.setHours(0, 0, 0, 0) > endTime.setHours(0, 0, 0, 0)) {
          this.setState({
            dateErrorMsg: true,
          });
        } else {
          this.setState({
            dateErrorMsg: false,
          });
        }
      }
    );
  };

  setEndDatepickerValue = (selectedDate) => {
    let startTime = this.state.reportStartDate;
    let endTime = selectedDate;
    this.setState(
      {
        reportEndDate: selectedDate,
      },
      () => {
        if (startTime.setHours(0, 0, 0, 0) > endTime.setHours(0, 0, 0, 0)) {
          this.setState({
            dateErrorMsg: true,
          });
        } else {
          this.setState({
            dateErrorMsg: false,
          });
        }
      }
    );
  };

  onColumnSort = (sortModel) => {
    let sortObject = {};
    if (sortModel[0].field == "interviewReferenceNumber")
      sortObject["irnno"] = sortModel[0].sort;
    if (sortModel[0].field == "phoneCallDuration")
      sortObject["duration"] = sortModel[0].sort;
    this.setState(
      (preState) => {
        return {
          ...preState,
          sortOrder: sortObject,
          loading: true,
        };
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
      }
    );
  };
  onChangeTimeZone = (option) => {
    const timearray = this.state.displayTImeZoneOption;
    console.log("onChangeTimeZone", option);
    console.log("timearray", timearray);
    let filterOffset =
      timearray != undefined &&
      timearray.filter(function (timearray) {
        return timearray.value === option.value;
      });
    console.log(filterOffset);

    this.setState(
      {
        displayTimeZone: option,
        displayTimeZoneOffset: filterOffset[0].offset,
      },
      () => {
        console.log("off set on timezone", this.state);
      }
    );
  };

  secondsToMinutes = (time) => {
    return Math.floor(time / 60) + ":" + Math.floor(time % 60);
  };

  getIncompletCallRows = (data) => {
    let row = data.map((call, index) => {
      return {
        interviewReferenceNumber: call.interviewReferenceNumber,
        clientName: call.clientName,
        callerId: call.callerId,
        accessCode: call.accessCode,
        phoneCallInterruptCode: call.phoneCallInterruptCode,
        date:
          call.date != null
            ? moment(call.date)
                .utcOffset(this.state.displayTimeZoneOffset)
                .format(Date_Time_Format)
            : "-",
        phoneCallDuration: this.secondsToMinutes(call.phoneCallDuration),
        interviewResultId: call.interviewResultId,
      };
    });
    return row;
  };

  handleMinDuration = (option) => {
    console.log(option);
    if (option !== null) {
      let value = option.value;
      switch (value) {
        case "1": {
          this.setState({
            durationOption: option,
            maxDuration: 60,
            minDuration: 0,
          });
          break;
        }
        case "2": {
          this.setState({
            durationOption: option,
            maxDuration: 240,
            minDuration: 60,
          });
          break;
        }
        case "3": {
          this.setState({
            durationOption: option,
            maxDuration: 0,
            minDuration: 240,
          });
          break;
        }
      }
    } else {
      this.setState({
        durationOption: null,
        maxDuration: 0,
        minDuration: 0,
      });
    }
  };
  onChangeRecords = (perPageCount) => {
    this.setState(
      {
        perPageCount: perPageCount.pageSize,
        currentPage: perPageCount.page,
        loading: true,
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
      }
    );
  };

  render() {
    const { isResultDownload, alertMsg, variant, alertStatus } = this.state;
    const { t } = this.props;
    const minDuration = [
      { value: "1", label: `${t("Less than 1 minute")}` },
      { value: "2", label: `${t("Between 1 minute and 4")}` },
      { value: "3", label: `${t("More than 4 minutes")}` },
    ];
    let rows = this.getIncompletCallRows(this.state.resultdata);

    let columns = [
      {
        headerName: `${t("Irn")}`,
        field: "interviewReferenceNumber",
        sortable: true,
        flex: 1,
      },
      {
        headerName: `${t("Client Name")}`,
        field: "clientName",
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t("800#")}`,
        field: "callerId",
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t("Access Code")}`,
        field: "accessCode",
        flex: 1,
        sortable: false,
      },
      {
        headerName: `${t("Reason_Code")}`,
        field: "phoneCallInterruptCode",
        flex: 1,
        sortable: false,
      },
      {
        headerName: `${t("Call Date Time")}`,
        field: "date",
        flex: 1,
        sortable: false,
      },
      {
        headerName: `${t("Call Duration")}`,
        field: "phoneCallDuration",
        sortable: true,
        flex: 1,
      },
    ];

    return (
      <div className="w-1200">
        {isResultDownload && <FullScreenLoader message={"Please Wait"} />}
        <h6 className="account-labels row">
          <Grid item size={12} className="mt-2">
            <span className="float-right ml-4 badge badge-sm badge-primary">
              {this.state.selectedAcccountLabel != undefined &&
              this.state.selectedAcccountLabel != ""
                ? `Account : ${this.state.selectedAcccountLabel}`
                : ""}
            </span>
            <span className="float-right ml-4 badge badge-sm badge-primary">
              {this.state.selectedProjectLabel != undefined &&
              this.state.selectedProjectLabel != ""
                ? `Project : ${this.state.selectedProjectLabel}`
                : ""}
            </span>
          </Grid>
        </h6>
        <Grid container className="participants-page">
          <Grid item size={12}>
            <div className="">
              <Card>
                <CardHeader
                  title={
                    <Grid container>
                      <div className="col-lg-4"> {t("Accounts Options")}</div>
                      <div className="col-lg-2"> {t("Display Options")}</div>
                      <div className="col-lg-4"> {t("Filter by Date")}</div>
                      <div className="col-lg-2"> {t("Table Options")}</div>
                    </Grid>
                  }
                ></CardHeader>
                <CardContent className="resultsearchindex">
                  <Grid container>
                    <div className="position-relative col-lg-2">
                      <Select
                        name="status"
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.tenantDropOption}
                        isClearable={true}
                        placeholder={t("Select Account")}
                        onChange={this.onChangeTenant}
                      />
                    </div>
                    <div className="position-relative col-lg-2">
                      <Select
                        name="location"
                        ref={(ref) => {
                          this.selectRef = ref;
                        }}
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.projectDropDownOption}
                        isClearable={true}
                        placeholder={t("Select Project")}
                        onChange={this.onChangeTenantProject}
                      />
                    </div>
                    <div className="position-relative col-lg-2">
                      <Select
                        name="location"
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.displayTImeZoneOption}
                        isClearable={false}
                        value={this.state.displayTimeZone}
                        placeholder={t("Select Timezone")}
                        onChange={(option) => this.onChangeTimeZone(option)}
                      />
                    </div>
                    <div className="position-relative col-lg-4">
                      <Grid container>
                        <div className="position-relative col-lg-6">
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            placeholderText="Start Date"
                            autoComplete="off"
                            className="form-control w-100 "
                            name="reportStartDate"
                            onChange={(selectedDate) => {
                              this.setStartDatepickerValue(selectedDate);
                            }}
                            selected={this.state.reportStartDate}
                            label={t("From")}
                          />
                        </div>
                        <div className="position-relative col-lg-6">
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            placeholderText="End Date"
                            autoComplete="off"
                            className="form-control w-100 "
                            name="reportEndDate"
                            onChange={(selectedDate) => {
                              this.setEndDatepickerValue(selectedDate);
                            }}
                            selected={this.state.reportEndDate}
                            label={t("To")}
                          />
                        </div>
                      </Grid>
                      {this.state.dateErrorMsg ? (
                        <span className="input-feedback">
                          End Date must be greater than Start Date
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="position-relative col-lg-2">
                      <div className="position-relative">
                        <div className="data-option-container">
                          <div className="data-control">
                            <div className="d-flex align-items-center download-btn">
                              <div>
                                <img
                                  src={
                                    this.props.themeColor === "light"
                                      ? dataoptions
                                      : dataoptionsw
                                  }
                                />
                              </div>
                              <span className="text-uppercase ml-3">
                                {t("Data Options")}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <nav className="data-menu nav">
                              <div className="nav-item">
                                <div className="d-flex align-items-center download-btn data-item">
                                  <div>
                                    <img
                                      src={
                                        this.props.themeColor === "light"
                                          ? dataoptions
                                          : dataoptionsw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase ml-3">
                                    {t("Data Options")}
                                  </span>
                                </div>
                              </div>
                              <div className="nav-item">
                                <div
                                  className="d-flex align-items-center download-btn data-item"
                                  onClick={() =>
                                    this.exportIncompleteCalls(false)
                                  }
                                >
                                  <div>
                                    <img
                                      src={
                                        this.props.themeColor === "light"
                                          ? export_icon
                                          : export_iconw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase ml-3">
                                    {t("Export Records")}
                                  </span>
                                </div>
                              </div>
                              <div className="nav-item">
                                <div
                                  className="d-flex align-items-center download-btn data-item"
                                  onClick={() =>
                                    this.exportIncompleteCalls(true)
                                  }
                                >
                                  <div>
                                    <img
                                      src={
                                        this.props.themeColor === "light"
                                          ? export_icon
                                          : export_iconw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase ml-3">
                                    {t("Export All Results")}
                                  </span>
                                </div>
                              </div>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid item size={12}>
            <div className="">
              <Card>
                <CardHeader
                  title={
                    <Grid container>
                      <div className="col-lg-3"> {t("Search For Call")}</div>
                      <div className="col-lg-4"> {t("Filter")}</div>
                    </Grid>
                  }
                ></CardHeader>
                <CardContent>
                  <Grid container>
                    <div className="position-relative col-lg-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("Search")}
                        onChange={this.searchResultsByText}
                      />
                    </div>

                    <div className="position-relative col-lg-3">
                      <Select
                        name="location"
                        className="select-style"
                        classNamePrefix="select"
                        options={minDuration}
                        isClearable={true}
                        placeholder={t("Min Duration")}
                        onChange={this.handleMinDuration}
                      />
                    </div>

                    <div className="position-relative col-lg-1">
                      <Button
                        variant="primary"
                        ref=""
                        disabled={this.state.dateErrorMsg}
                        onClick={(e) => this.handlefilterApplyChange()}
                        className="text-uppercase btn-sm-text float-right"
                      >
                        {t("Apply")}
                      </Button>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item size={12}>
            <AlertDismissible
              msg={alertMsg}
              variantType={variant}
              show={alertStatus}
              close={() => {
                this.setState({
                  alertStatus: false,
                });
              }}
            ></AlertDismissible>
          </Grid>
          <Grid item size={12}>
            <Card>
              <div className="table_wrapper custom_table_container app-table  incompletecalls-module-table">
                <CustomGrid
                  columns={columns}
                  data={rows}
                  loading={this.state.loading}
                  currentPage={this.state.currentPage}
                  perPageCount={this.state.perPageCount}
                  totalPages={this.state.totalResults}
                  getRowId={(row) => row.interviewResultId}
                  isServerPagination={true}
                  isServerSorting={true}
                  onChangeRecords={this.onChangeRecords}
                  onSortModelChange={this.onColumnSort}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
  };
};
export default withTranslation()(connect(mapStateToProps)(IncompleteCalls));
