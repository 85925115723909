import React, { Component } from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Card, CardContent, CardHeader, Grid2 as Grid } from "@mui/material";
import moment from "moment";
import * as FileSaver from "file-saver";
import dataoptions from "../../../assets/icon/dataoptions.png";
import dataoptionsw from "../../../assets/icon/dataoptionsw.png";
import export_icon from "../../../assets/icon/export_icon.png";
import export_iconw from "../../../assets/icon/export_iconw.png";
import downloadPdfIcon from "../../../assets/images/pdf_download.png";
import {
  Alert as AlertDismissible,
  Button,
  Checkboxes,
  ModalPopUp,
} from "../../../components/Common";
import {
  Date_Format,
  Date_Time_Format,
  Send_Api_Date_Format,
} from "../../../config";
import { getlanguageIdFromcode, sortByLabel } from "../../../Helpers/index";
import i18n from "../../../locale/i18n";
import {
  exportInterviewResults,
  InterviewResultsReport,
  releaseProjectResults,
  updateResultMitigation,
} from "../../../Services/executionService";
import {
  getAllAttribute,
  getProjectQuestions,
} from "../../../Services/projectServices";
import { getTenantProjects } from "../../../Services/userServices";
import { CustomGrid } from "../../CustomTable";
import { CustomTooltip } from "../../Utility/CustomTooltip";
import { formatDate } from "../../Utility/DatePickerFunctions";
import FullScreenLoader from "../../Utility/FullScreenLoader";
import { generatePdfDocument } from "../../Utility/ResultPdf";
import StandardDatePicker from "../../Utility/StandardDatePicker";

class ReportsCallrecords extends Component {
  constructor(props) {
    const displayTImeZoneOption = require("../../../config/timezone.json");
    const selectRef = null;
    super(props);
    this.state = {
      filterriskHigh: true,
      filterriskAverage: true,
      filterriskPotential: true,
      filterriskLow: true,
      filterriskAd: false,
      filterriskCm: false,
      filterriskNc: false,
      filterOverallriskNcAd: true,
      filterOverallriskDc: true,
      filteredResult: {},
      resultdata: [],
      rows: [],
      risklevel: ["HR", "LR", "AR", "PR", "NC", "RJ"],
      risklevelparam: [],
      searchText: "",
      questionDetail: [],
      labelStructure: "",
      onHoldCheck: false,
      rowsOnhold: [],
      rowsReleased: [],
      selectedResults: [],
      selectAll: false,
      checkedItems: new Map(),
      projectId: "",
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isinternal: [],
      filexls: "",
      filecop: "",
      uploadResultsModalShow: false,
      resultMitigationModalShow: false,
      uploadResultsModalMsg: "",
      noPermissionmsg: "Loading...",
      loading: true,
      projectCode: "",
      currentPage: 0,
      perPageCount: 25,
      totalPages: 0,
      totalResults: 0,
      sortOrder: {
        // "date":"desc"
      },
      mitigationResponse: [],
      mitigationActionValue: "",
      mitigationVerifiedValue: "",
      mitigationActions: [],
      mitigationStatus: [],
      resultMitigationDate: "",
      mitigationNotes: "",
      currentMitigationResultId: "",
      allAttribute: [],
      finalMitigationStatus: null,
      alertMsgmitigate: "",
      alertStatusmitigate: false,
      variantmitigate: "",
      language: i18n.language,
      reportStartDate: new Date(),
      reportEndDate: new Date(),
      accountsDetails: [],
      tenantDropOption: [],
      projectDropDownOption: [],
      selectedProjectId: "",
      selectedProjectLabel: "",
      selectedAcccountId: "",
      selectedAcccountLabel: "",
      //currentPage:0,
      displayTimeZone: {
        label: "(GMT-8) Pacific Time (US & Canada)",
        value: "6",
      },
      displayTImeZoneOption: displayTImeZoneOption,
      displayTimeZoneOffset: "-08:00",
      callRecordsData: [],
      selectAllCheckbox: false,
      selectAllPageData: false,
      selectedParticipant: [],
      dateErrorMsg: false,
      isResultDownload: false,
    };
    this.fileInput = React.createRef();
  }
  handleModalClose = () => {
    this.setState({
      uploadResultsModalShow: false,
    });
  };

  handleMitigationModalClose = () => {
    this.setState({
      resultMitigationModalShow: false,
    });
  };

  getMitigationHistory = (response, language) => {
    let mitigationHistory =
      response !== undefined &&
      response.map((item, index) => {
        return (
          <div className="mitigate-history card">
            <p className="mb-1 text-blue">
              Date Recorded:
              {item.followUpDate != null
                ? moment(item.followUpDate).local().format(Date_Format)
                : null}
            </p>
            <p className="mb-1">{item.note} </p>
            <p className="mb-1 text-blue">
              <span className="float-right">
                <span>
                  {this.getAttributeLabel(language, item.followUpActionId)}
                </span>
                <span>
                  Date:
                  {item.createTs != null
                    ? moment(item.createTs).local().format(Date_Time_Format)
                    : null}
                </span>
                by <span>[{item.user.firstName}]</span>
              </span>
            </p>
          </div>
        );
      });
    return mitigationHistory;
  };

  setDatepickerValue = (d) => {
    console.log("setDatepickerValue", d);
    this.setState({
      resultMitigationDate: d,
    });
  };

  saveMitigationResults = () => {
    console.log("saveMitigationResults");
    let response = this.getmitigationresponse();
    if (
      this.state.mitigationActionValue !== null &&
      this.state.mitigationVerifiedValue !== null &&
      this.state.resultMitigationDate !== "" &&
      this.state.mitigationNotes !== ""
    ) {
      updateResultMitigation(response)
        .then((res) => {
          console.log(res);
          this.setState(
            {
              mitigationResponse: res.data,
              finalMitigationStatus:
                res.data.length !== 0 ? res.data[0].followUpActionId : null,
              mitigationActionValue: "",
              mitigationVerifiedValue: "",
              resultMitigationDate: "",
              mitigationNotes: "",
              alertMsgmitigate: "",
              alertStatusmitigate: false,
              variantmitigate: "",
            },
            () => {
              let initialdata = this.initialdata();
              this.callProjectResultApi(initialdata);
            }
          );
        })
        .catch((err) => {
          this.setState({
            alertMsgmitigate: "We are unable to process this request!",
            alertStatusmitigate: true,
            variantmitigate: "danger",
          });
        });
    } else {
      console.log("false");
      this.setState({
        alertMsgmitigate: "Please fill all mandatory fileds",
        alertStatusmitigate: true,
        variantmitigate: "danger",
      });
    }
  };

  getmitigationresponse = () => {
    let postdata = {
      resultId: this.state.currentMitigationResultId,
      followUpActionId: this.state.mitigationActionValue,
      followUpStatusId: this.state.mitigationVerifiedValue,
      followUpDate: moment(this.state.resultMitigationDate).format("x"),
      note: this.state.mitigationNotes,
    };
    return postdata;
  };

  componentDidMount() {
    let userinfo = JSON.parse(localStorage.getItem("userInfo"));
    let internalPermissions = userinfo.rolePermission.permissions;
    console.log("internalPermissions", internalPermissions);

    let isinternalarray = [];
    internalPermissions.map((item, index) => {
      isinternalarray.push(item.permission);
      if (index == internalPermissions.length - 1) {
        this.setState(
          {
            isinternal: [...isinternalarray],
            noPermissionmsg: "",
            labelStructure: this.questionLabelStructureAccount(),
          },
          () => {
            console.log(
              "compoinentdid mount call records",
              index,
              this.state.isinternal
            );
          }
        );
      }
    });
    const self = this;

    getTenantProjects()
      .then((res) => {
        console.log(res);
        let tenantDropDown = res.data
          .map((option) => {
            return {
              value: option.tenantId,
              label: option.tenantName,
            };
          })
          .sort(sortByLabel);

        this.setState(
          {
            accountsDetails: res.data,
            tenantDropOption: tenantDropDown,
          },
          () => {
            let datafiltered = this.initialdata();

            this.callProjectResultApi(datafiltered);
          }
        );
      })
      .catch((err) => {});
    self.loadAllAttribute();
    this.props.action("Results");
    i18next.on("languageChanged", function (lng) {
      self.setState(
        {
          language: lng,
        },
        () => {
          self.loadAllAttribute();
        }
      );
    });
  }

  getQuestionsFromProject = (results, Projectid, irn) => {
    getProjectQuestions(Projectid)
      .then((res) => {
        this.setState(
          {
            questionDetail: res.data.questions,
          },
          () => {
            generatePdfDocument(
              results,
              this.state.questionDetail,
              irn,
              "",
              this.state.displayTimeZoneOffset
            );
          }
        );
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
        });
        this.setState({});
      });
  };

  getOnchangeAccount = (operatingTenant, data) => {
    let selectedAccount = data.filter((item) => {
      return item.tenantId == operatingTenant;
    });
    console.log(selectedAccount[0]);

    if (
      selectedAccount[0] != undefined &&
      selectedAccount[0].projects != null
    ) {
      let projectDropDown = selectedAccount[0].projects
        .map((option) => {
          return {
            value: option.projectId,
            label: option.name,
          };
        })
        .sort(sortByLabel);
      return projectDropDown;
    }
  };

  onChangeTenant = (e) => {
    console.log(e);
    if (e !== null) {
      let operatingTenant = e.value;
      let projectDropDown = this.getOnchangeAccount(
        operatingTenant,
        this.state.accountsDetails
      );
      this.setState(
        {
          projectDropDownOption: projectDropDown,
          selectedAcccountId: e.value,
          selectedAcccountLabel: e.label,
          selectedProjectId: "",
          selectedProjectLabel: "",
          currentPage: 0,
          totalResults: 0,
          totalPages: 0,
          alertMsg: "",
          variant: "",
          alertStatus: false,
          loading: true,
        },
        () => {
          let initialdata = this.initialdata();
          this.callProjectResultApi(initialdata);
        }
      );
    } else {
      //this.selectRef.select.clearValue();
      this.setState(
        {
          projectDropDownOption: [],
          selectedAcccountId: "",
          selectedAcccountLabel: "",
          selectedProjectId: "",
          selectedProjectLabel: "",
          currentPage: 0,
          totalResults: 0,
          totalPages: 0,
          alertMsg: "",
          variant: "",
          alertStatus: false,
          loading: true,
        },
        () => {
          let initialdata = this.initialdata();
          this.callProjectResultApi(initialdata);
        }
      );
    }
  };

  onChangeTenantProject = (e) => {
    console.log(e);
    if (e !== null) {
      this.setState(
        {
          selectedProjectId: e.value,
          selectedProjectLabel: e.label,
          currentPage: 0,
          loading: true,
        },
        () => {
          let initialdata = this.initialdata();
          this.callProjectResultApi(initialdata);
        }
      );
    } else {
      this.setState(
        {
          selectedProjectId: "",
          selectedProjectLabel: "",
          currentPage: 0,
          loading: true,
        },
        () => {
          let initialdata = this.initialdata();
          this.callProjectResultApi(initialdata);
        }
      );
    }
  };

  onChangeTimeZone = (option) => {
    const timearray = this.state.displayTImeZoneOption;
    let filterOffset =
      timearray != undefined &&
      timearray.filter(function (timearray) {
        return timearray.value === option.value;
      });
    console.log(filterOffset);

    this.setState(
      {
        displayTimeZone: option,
        displayTimeZoneOffset: filterOffset[0].offset,
      },
      () => {}
    );
  };

  setStartDatepickerValue = (selectedDate) => {
    let startTime = selectedDate;
    let endTime = this.state.reportEndDate;

    this.setState(
      {
        reportStartDate: selectedDate,
      },
      () => {
        if (startTime.setHours(0, 0, 0, 0) > endTime.setHours(0, 0, 0, 0)) {
          this.setState({
            dateErrorMsg: true,
          });
        } else {
          this.setState({
            dateErrorMsg: false,
          });
        }
      }
    );
  };

  setEndDatepickerValue = (selectedDate) => {
    let startTime = this.state.reportStartDate;
    let endTime = selectedDate;
    this.setState(
      {
        reportEndDate: selectedDate,
      },
      () => {
        if (startTime.setHours(0, 0, 0, 0) > endTime.setHours(0, 0, 0, 0)) {
          this.setState({
            dateErrorMsg: true,
          });
        } else {
          this.setState({
            dateErrorMsg: false,
          });
        }
      }
    );
  };

  handlefilterApplyChange = () => {
    let id = parseInt(this.props.match.params.id);
    let resklevelarray = [
      {
        label: "filterriskHigh",
        value: this.state.filterriskHigh,
        initial: "HR",
      },
      {
        label: "filterriskAverage",
        value: this.state.filterriskAverage,
        initial: "AR",
      },
      {
        label: "filterriskPotential",
        value: this.state.filterriskPotential,
        initial: "PR",
      },
      {
        label: "filterriskLow",
        value: this.state.filterriskLow,
        initial: "LR",
      },
      {
        label: "filterOverallriskNcAd",
        value: this.state.filterOverallriskNcAd,
        initial: "NC",
      },
      {
        label: "filterOverallriskNcAd",
        value: this.state.filterOverallriskNcAd,
        initial: "AD",
      },
      {
        label: "filterOverallriskRejected",
        value: this.state.filterOverallriskRejected,
        initial: "RJ",
      },
    ];
    let risklevelnew = [];
    resklevelarray.map((item, index) => {
      if (item.value) {
        risklevelnew.push(item.initial);
      }
      return risklevelnew;
    });

    this.setState(
      {
        risklevel: risklevelnew,
        currentPage: 0,
        loading: true,
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
      }
    );
  };

  onColumnSort = (sortModel) => {
    let sortObject = {};
    sortObject[sortModel[0]?.field] = sortModel[0]?.sort;
    this.setState(
      (preState) => {
        return {
          ...preState,
          sortOrder: sortObject,
          loading: true,
        };
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
      }
    );
  };

  initialdata = () => {
    let initialdata = {
      projectId: this.state.selectedProjectId,
      tenantId: this.state.selectedAcccountId,
      displayTimeZone: this.state.displayTimeZoneOffset,
      startDate: this.state.reportStartDate
        ? moment(this.state.reportStartDate).format(Send_Api_Date_Format)
        : this.state.reportStartDate,
      endDate: this.state.reportEndDate
        ? moment(this.state.reportEndDate).format(Send_Api_Date_Format)
        : this.state.reportEndDate,
      search: this.state.searchText,
      sortBy: this.state.sortOrder,
      page: this.state.currentPage,
      pageSize: this.state.perPageCount,
      riskLevels: this.state.risklevel,
      CM: this.state.filterriskCm,
      AD: this.state.filterriskAd,
      NC: this.state.filterriskNc,
      released: !this.state.onHoldCheck,
      exportResultsReport: true,
    };
    return initialdata;
  };

  onChangePage = (number) => {
    this.setState(
      {
        currentPage: number,
        selectedResults: [],
        selectAll: false,
        loading: true,
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
        this.resetAllcheckboxes();
      }
    );
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  handleAlertClosemitigate = () => {
    this.setState({
      alertStatusmitigate: false,
    });
  };

  resetOverallFilters = () => {
    this.setState({
      filterriskHigh: true,
      filterriskAverage: true,
      filterriskPotential: true,
      filterriskLow: true,
      filterOverallriskNcAd: true,
      filterOverallriskDc: true,
    });
  };

  handleDeleteClick = () => {
    let deletedata = this.state.selectedResults;
    let newdeletedata = deletedata.map(function (el) {
      let o = Object.assign({}, el);
      o.released = false;
      o.deleted = true;
      return o;
    });
    releaseProjectResults(newdeletedata)
      .then((res) => {
        let initialdata = this.initialdata();
        this.resetAllcheckboxes();
        this.callProjectResultApi(initialdata);
      })
      .catch((err) => {
        console.log("initial err", err);
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
        });
      });
  };

  handleReleaseClick = () => {
    let releasedata = this.state.selectedResults;
    let newreleasedata = releasedata.map(function (el) {
      let o = Object.assign({}, el);
      o.released = true;
      o.deleted = false;
      return o;
    });
    releaseProjectResults(newreleasedata)
      .then((res) => {
        console.log("releaseProjectResults", res);
        let initialdata = this.initialdata();
        this.resetAllcheckboxes();
        this.callProjectResultApi(initialdata);
      })
      .catch((err) => {
        console.log("initial err", err);
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
        });
      });
  };

  exportInterviewResults = (allRecords) => {
    console.log("exportInterviewResults");
    this.setState({
      isResultDownload: true,
    });
    let initialdata = this.initialdata();
    if (allRecords) initialdata.allRecords = allRecords;
    exportInterviewResults(initialdata)
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv; charset=utf-8" });
        FileSaver.saveAs(blob, "results.xlsx");
        this.setState({
          isResultDownload: false,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
          let errMsg = err?.response?.data?.message;
          this.setState({
            alertMsg: errMsg,
            variant: "danger",
            alertStatus: true,
            isResultDownload: false,
          });
        } else {
          let errMsg = err?.response?.data?.message;
          const textDecoder = new TextDecoder();
          const decode = textDecoder.decode(
            new Uint8Array(err?.response?.data)
          );
          const object = JSON.parse(decode);
          console.log("error in export participant", object?.message);
          this.setState({
            alertMsg:
              object?.status === 409
                ? object?.message
                : errMsg || object?.message,
            variant: "danger",
            alertStatus: true,
            isResultDownload: false,
          });
        }
      });
  };

  resetAllcheckboxes = () => {
    let resultdata = [...this.state.resultdata];
    resultdata.map((results) => {
      this.setState((preState) => {
        return {
          ...preState,
          selectedResults: [],
          selectAll: false,
          [results.interviewResultId]: false,
        };
      });
      return null;
    });
    this.updateResultsView(this.state.resultdata);
  };

  handleSelectAllResults = (event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let preSelect = [];
    let selectresdata = {};
    const resultsdata = [...this.state.resultdata];
    resultsdata != undefined &&
      resultsdata.map((results, index) => {
        if (!results.released) {
          if (value) {
            preSelect = [
              ...preSelect,
              {
                interviewResultId: results.interviewResultId,
                projectId: parseInt(this.props.match.params.id),
              },
            ];
          } else {
            preSelect = [];
          }
        }
        selectresdata = {
          ...selectresdata,
          [results.interviewResultId]: value,
        };
        return null;
      });
    this.setState(
      (preState) => {
        return {
          ...preState,
          ...selectresdata,
          selectedResults: preSelect,
          selectAll: value,
        };
      },
      () => {
        this.updateResultsView(resultsdata);
      }
    );
  };

  handleSelectSingleResults = (event, id, index, irn) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let preselectedResults = this.state.selectedResults;
    let newobject = {
      interviewResultId: id,
      projectId: parseInt(this.props.match.params.id),
    };
    if (value) {
      preselectedResults.push(newobject);
    } else {
      preselectedResults = preselectedResults.filter(function (obj) {
        return obj.interviewResultId !== id;
      });
    }
    this.setState(
      (preState) => {
        return {
          selectedResults: preselectedResults,
          [id]: value,
        };
      },
      () => {
        this.updateResultsView(this.state.resultdata);
      }
    );
    console.log(preselectedResults);
  };

  handleOnholdChange = (event) => {
    const target = event.target;
    const value = target.checked;
    console.log(value);

    this.setState(
      (preState) => {
        return {
          onHoldCheck: value,
          currentPage: 0,
        };
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
        this.resetAllcheckboxes();
      }
    );
  };

  handlefilterOverallriskNcAd = () => {
    const target = event.target;
    const value = target.checked;
    console.log(value);
    this.setState((preState) => {
      return {
        filterOverallriskNcAd: value,
      };
    });
  };

  handlefilterriskHighChange = (event) => {
    const target = event.target;
    const value = target.checked;
    console.log(value);
    this.setState((preState) => {
      return {
        filterriskHigh: value,
      };
    });
  };

  handlefilterriskAverageChange = (event) => {
    const target = event.target;
    const value = target.checked;
    console.log(value);
    this.setState((preState) => {
      return {
        filterriskAverage: value,
      };
    });
  };

  handlefilterriskPotentialChange = (event) => {
    const target = event.target;
    const value = target.checked;
    console.log(value);
    this.setState((preState) => {
      return {
        filterriskPotential: value,
      };
    });
  };

  handlefilterriskLowChange = (event) => {
    const target = event.target;
    const value = target.checked;
    console.log(value);
    this.setState((preState) => {
      return {
        filterriskLow: value,
      };
    });
    console.log(this.state.filterriskHigh);
  };

  handlefilterriskCmChange = (event) => {
    const target = event.target;
    const value = target.checked;
    if (value) {
      console.log(value);
      this.resetOverallFilters();
    }
    this.setState({
      filterriskCm: value,
    });
  };

  handlefilterriskAdChange = (event) => {
    const target = event.target;
    const value = target.checked;
    if (value) {
      console.log(value);
      this.resetOverallFilters();
    }
    this.setState({
      filterriskAd: value,
    });
  };

  getInitials = (initial) => {
    if (
      initial === "LR" ||
      initial === "PR" ||
      initial === "HR" ||
      initial === "AR"
    ) {
      return initial.split("")[0];
    } else {
      return initial;
    }
  };

  getInitialsSeq = (initial) => {
    if (initial === "AD") {
      return 1;
    }
    if (initial === "NC") {
      return 2;
    }
    if (initial === "HR") {
      return 3;
    }
    if (initial === "PR") {
      return 4;
    }
    if (initial === "AR") {
      return 5;
    }
    if (initial === "LR") {
      return 6;
    }
    if (initial === "RJ") {
      return 7;
    } else {
      return 0;
    }
  };

  getResultsRows = (results) => {
    const { t } = this.props;
    let row =
      results !== undefined &&
      results.map((results, index) => {
        return {
          key: index,
          id: results.interviewResultId,
          irnno: results.interviewReferenceNumber,
          fullname: results.participantName,
          clientname: results.clientName,
          projectName: results.projectName,
          position: results.position,
          date: results.dateString,
          accesscode: results.accessCode,
          overall: results.overallEvaluation,
          questions: results.interviewQuestionsResult,
          btnPdf: results,
          check: results,
        };
      });
    return row;
  };

  questionStructure = (dataq) => {
    let filteredquestionArray = [
      {
        sequence: "1",
        result: "",
      },
      {
        sequence: "2",
        result: "",
      },
      {
        sequence: "3",
        result: "",
      },
      {
        sequence: "4",
        result: "",
      },
      {
        sequence: "5",
        result: "",
      },
      {
        sequence: "6",
        result: "",
      },
      {
        sequence: "7",
        result: "",
      },
    ];
    const newItemsBySequence = dataq.reduce((a, item) => {
      a[item.sequence] = item;
      return a;
    }, {});
    const filteredOutput = filteredquestionArray.map((item) =>
      newItemsBySequence[item.sequence]
        ? newItemsBySequence[item.sequence]
        : item
    );
    let finalHTML = filteredOutput
      .sort((a, b) => a.sequence - b.sequence)
      .map((item, index) => {
        return (
          <div>
            <FilterLabel
              key={item.id}
              stylename="float-left"
              label=""
              initial={this.getInitials(item.result)}
              color={item.result}
            />
          </div>
        );
      });
    return finalHTML;
  };

  questionLabelStructureAccount = () => {
    let filtered = [1, 2, 3, 4, 5, 6, 7];
    let labelStructure = filtered.map((item, index) => {
      return (
        <div key={index} className="float-left question-label">
          <span data-tip="" data-for={`test_${index})`}>
            {item}
          </span>
        </div>
      );
    });
    return labelStructure;
  };

  getMaskedAccessCode = (numbe) => {
    if (numbe != null) {
      const numb = numbe.toString();
      let number = `${numb.substring(0, 3)}-${numb.substring(
        3,
        6
      )}-${numb.substring(6, numb.length)}`;
      return number;
    }
  };

  searchResultsByText = (event) => {
    let text = event.target.value;
    this.setState(
      (preState) => {
        return { searchText: text, loading: true };
      },
      () => {
        this.searchApi();
      }
    );
  };

  searchApi = () => {
    let initialdata = this.initialdata();
    this.callProjectResultApi(initialdata);
  };

  loadAllAttribute = () => {
    let languagecode = i18n.language;
    let language = getlanguageIdFromcode(languagecode);
    console.log("loadAllAttribute", i18n.language);
    getAllAttribute()
      .then((res) => {
        console.log("getAllAttribute", res);
        let mitigationActions = res.data.attributes.filter((item) => {
          return (
            item.attributeType === "RESULT_FOLLOW_UP_ACTION" &&
            item.languageId === language
          );
        });
        let mitigationStatus = res.data.attributes.filter((item) => {
          return (
            item.attributeType === "RESULT_FOLLOW_UP_STATUS" &&
            item.languageId === language
          );
        });
        let mitigationActionsOption = mitigationActions
          .map((option) => {
            return this.createDropdownOption(option);
          })
          .sort(sortByLabel);

        let mitigationStatusOption = mitigationStatus
          .map((option) => {
            return this.createDropdownOption(option);
          })
          .sort(sortByLabel);

        this.setState({
          mitigationActions: mitigationActionsOption,
          mitigationStatus: mitigationStatusOption,
          allAttribute: res.data.attributes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAttributeLabel = (languageCode, id) => {
    let language = getlanguageIdFromcode(languageCode);
    let response = [...this.state.allAttribute];
    console.log("getAttributeLabel", this.state.allAttribute);
    let attributeLabel = response.filter((item) => {
      return item.attributeId === id && item.languageId === language;
    });
    let finalattrlabel;
    attributeLabel.map(function (item, index) {
      finalattrlabel = item.description;
    });
    console.log("getAttributeLabel", attributeLabel);
    return finalattrlabel;
  };

  createDropdownOption = (option) => {
    return {
      value: option.attributeId,
      label: option.description,
      sequence: option.sequence,
    };
  };

  onChangeRecords = (perPageCount) => {
    this.setState(
      {
        perPageCount: perPageCount.pageSize,
        currentPage: perPageCount.page,
        loading: true,
      },
      () => {
        let initialdata = this.initialdata();
        this.callProjectResultApi(initialdata);
      }
    );
  };

  updateResultsView = (response) => {
    console.log("updateResultsView", response);
    let rows = this.getResultsRows(response);
    let filtered = response.filter(function (item, index) {
      return item.released === false;
    });
    let filtered2 = response.filter(function (item, index) {
      return item.released === true;
    });
    let rowsHold = this.getResultsRows(filtered);
    let rowsrel = this.getResultsRows(filtered2);
    console.log("row", rows);
    this.setState({
      rows: rows,
      rowsOnhold: rowsHold,
      rowsReleased: rowsrel,
    });
  };

  onMitigationActionUpdate = (option) => {
    let text = option != null ? option.value : null;
    this.setState(
      {
        mitigationActionValue: text,
      },
      () => {}
    );
  };

  onMitigationNotesUpdate = (event) => {
    this.setState(
      {
        mitigationNotes: event.target.value,
      },
      () => {}
    );
  };

  onMitigationVerifiedUpdate = (option) => {
    let text = option != null ? option.value : null;
    this.setState(
      {
        mitigationVerifiedValue: text,
      },
      () => {}
    );
  };

  callProjectResultApi = (initialdata) => {
    this.setState(
      {
        alertStatus: false,
        resultdata: [],
        rows: [],
        rowsOnhold: [],
        rowsReleased: [],
        totalResults: 0,
      },
      () => {
        InterviewResultsReport(initialdata)
          .then((res) => {
            console.log("this 1", this);
            if (!res.data.results.length) {
              this.setState({
                alertMsg: this.props.t("There are no results to show."),
                variant: "success",
                alertStatus: true,
                loading: false,
              });
            }
            this.setState(
              {
                resultdata: res.data.results,
                totalResults: res.data.totalResults,
                totalPages: Math.ceil(
                  res.data.totalResults / this.state.perPageCount
                ),
                loading: false,
              },
              () => {
                const resultresponse = res.data.results;
                this.updateResultsView(resultresponse);
              }
            );
          })
          .catch((err) => {
            console.log("initial err", err);
            let errMsg = err.response?.data.message;
            this.setState({
              alertMsg: errMsg,
              variant: "danger",
              alertStatus: true,
              loading: false,
            });
          });
      }
    );
  };

  render() {
    const { isResultDownload } = this.state;
    const { t } = this.props;
    let rowsdata = [];
    let tmdbData = {};
    rowsdata = [...this.state.rows];
    const rowsdatahold = this.state.rowsOnhold;
    const rowsdatareleased = this.state.rowsReleased;
    tmdbData = {
      columns: [
        {
          headerName: "IRN",
          field: "irnno",
          sortable: true,
          width: 160,
        },
        {
          headerName: t("Client Name"),
          field: "clientname",
          width: 160,
          sortable: false,
        },
        {
          headerName: t("Project Name"),
          field: "projectName",
          width: 180,
          sortable: false,
        },

        {
          headerName: t("Date"),
          field: "date",
          sortable: true,
          renderCell: (params) =>
            params.value
              ? moment(params.value).format(Date_Format)
              : params.value,
          width: 120,
        },
        {
          headerName: t("Access Code"),
          field: "accesscode",
          width: 160,
          sortable: false,
          renderCell: (params) =>
            this.getMaskedAccessCode(params.value.accessCode),
        },
        {
          headerName: t("OverAll"),
          field: "overall",
          sortable: true,
          width: 120,
          renderCell: (params) => {
            return (
              <>
                <FilterLabel
                  label=""
                  initialseq={this.getInitialsSeq(params.row.overall)}
                  initial={this.getInitials(params.row.overall)}
                  color={params.row.overall}
                />
              </>
            );
          },
        },
        {
          headerName: this.state.labelStructure,
          field: "questions",
          width: 460,
          renderCell: (params) =>
            params.row.questions !== null
              ? this.questionStructure(params.value)
              : "",
          sortable: false,
        },
        {
          headerName: "Pdf",
          field: "btnPdf",
          width: 50,
          sortable: false,
          renderCell: (params) => {
            return (
              <>
                <div
                  className="action-event vertical-align-middle display-inline"
                  onClick={() =>
                    this.getQuestionsFromProject(
                      params.value,
                      params.value.projectId,
                      params.value.interviewReferenceNumber
                    )
                  }
                >
                  <img src={downloadPdfIcon} className="pdf-download-icon" />
                </div>
              </>
            );
          },
        },
        ...(this.state.isinternal.indexOf(
          "tenant.project.resultshold.internal.update"
        ) >= 0
          ? [
              {
                headerName:
                  this.state.isinternal.indexOf(
                    "tenant.project.resultshold.internal.update"
                  ) >= 0 ? (
                    <Checkboxes
                      className="cs_header_check form-control"
                      checked={this.state.selectAll}
                      onChange={(event) => this.handleSelectAllResults(event)}
                      indeterminate={
                        this.state.selectedResults[0] !==
                          this.state.selectedResults[1] &&
                        this.state.resultdata.length !==
                          this.state.selectedResults.length
                      }
                    />
                  ) : (
                    ""
                  ),
                field: "check",
                sortable: false,
                width: 70,
                align: "center",
                renderCell: (params) => {
                  return (
                    <>
                      {this.state.isinternal != undefined &&
                      this.state.isinternal.indexOf(
                        "tenant.project.resultshold.internal.update"
                      ) >= 0 ? (
                        !params.value.released ? (
                          <Checkboxes
                            type="checkbox"
                            name={params.value.interviewResultId}
                            checked={
                              this.state[params.value.interviewResultId]
                                ? true
                                : false
                            }
                            onChange={(event) =>
                              this.handleSelectSingleResults(
                                event,
                                params.value.interviewResultId,
                                params.value.interviewReferenceNumber
                              )
                            }
                            label=""
                            className="form-control cs_header_check"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="tableicon"
                            icon={faShareSquare}
                          />
                        )
                      ) : !params.value.released ? (
                        ""
                      ) : (
                        ""
                      )}
                    </>
                  );
                },
              },
            ]
          : []),
      ],
      rows:
        this.state.isinternal.indexOf(
          "tenant.project.resultshold.internal.update"
        ) >= 0
          ? this.state.onHoldCheck
            ? rowsdatahold
            : rowsdata
          : rowsdatareleased,
    };

    return (
      <React.Fragment>
        <div className="w-1200">
          {isResultDownload && <FullScreenLoader message={"Please Wait"} />}
          {this.state.isinternal &&
          this.state.isinternal.indexOf("tenant.project.report.call.read") >=
            0 ? (
            <React.Fragment>
              <h6 className="account-labels row">
                <Grid item size={12} className="mt-2">
                  <span className="float-right ml-4 badge badge-sm badge-primary">
                    {this.state.selectedAcccountLabel != undefined &&
                    this.state.selectedAcccountLabel != ""
                      ? `Account : ${this.state.selectedAcccountLabel}`
                      : ""}
                  </span>
                  <span className="float-right ml-4 badge badge-sm badge-primary">
                    {this.state.selectedProjectLabel != undefined &&
                    this.state.selectedProjectLabel != ""
                      ? `Project : ${this.state.selectedProjectLabel}`
                      : ""}
                  </span>
                </Grid>
              </h6>
              <Grid container className="participants-page" spacing={2}>
                <Grid item size={12}>
                  <div>
                    <Card>
                      <CardHeader
                        title={
                          <Grid container>
                            <div className="col-lg-4">
                              {t("Accounts Options")}
                            </div>
                            <div className="col-lg-2">
                              {t("Display Options")}
                            </div>
                            <div className="col-lg-4">
                              {t("Filter by Date")}
                            </div>
                            <div className="col-lg-2">{t("Table Options")}</div>
                          </Grid>
                        }
                      ></CardHeader>
                      <CardContent className="resultsearchindex">
                        <Grid container>
                          <div className="position-relative col-lg-2">
                            <Select
                              name="status"
                              className="select-style"
                              classNamePrefix="select"
                              options={this.state.tenantDropOption}
                              isClearable={true}
                              placeholder={t("Select Account")}
                              onChange={this.onChangeTenant}
                            />
                          </div>
                          <div className="position-relative col-lg-2">
                            <Select
                              ref={(ref) => {
                                this.selectRef = ref;
                              }}
                              name="location"
                              className="select-style"
                              classNamePrefix="select"
                              options={this.state.projectDropDownOption}
                              isClearable={true}
                              placeholder={t("Select Project")}
                              onChange={this.onChangeTenantProject}
                            />
                          </div>
                          <div className="position-relative col-lg-2">
                            <Select
                              name="location"
                              className="select-style"
                              classNamePrefix="select"
                              options={this.state.displayTImeZoneOption}
                              isClearable={true}
                              value={this.state.displayTimeZone}
                              placeholder={t("Select Timezone")}
                              onChange={this.onChangeTimeZone}
                            />
                          </div>
                          <div className="position-relative col-lg-4">
                            <Grid container>
                              <div className="position-relative col-lg-6">
                                <StandardDatePicker
                                  dateFormat={formatDate()}
                                  placeholderText="Start Date"
                                  autoComplete="off"
                                  className="form-control w-100 "
                                  name="reportStartDate"
                                  onChange={(selectedDate) => {
                                    this.setStartDatepickerValue(selectedDate);
                                  }}
                                  selected={this.state.reportStartDate}
                                  label={t("From")}
                                />
                              </div>
                              <div className="position-relative col-lg-6">
                                <StandardDatePicker
                                  dateFormat={formatDate()}
                                  placeholderText="End Date"
                                  autoComplete="off"
                                  className="form-control w-100 "
                                  name="reportEndDate"
                                  onChange={(selectedDate) => {
                                    this.setEndDatepickerValue(selectedDate);
                                  }}
                                  selected={this.state.reportEndDate}
                                  label={t("To")}
                                />
                              </div>
                            </Grid>
                            {this.state.dateErrorMsg ? (
                              <span className="input-feedback">
                                End Date must be greater than Start Date
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="position-relative col-lg-2">
                            <div className="position-relative">
                              <div className="data-option-container">
                                <div className="data-control">
                                  <div className="d-flex align-items-center download-btn">
                                    <div>
                                      <img
                                        src={
                                          this.props.themeColor === "light"
                                            ? dataoptions
                                            : dataoptionsw
                                        }
                                      />
                                    </div>
                                    <span className="text-uppercase ml-3">
                                      {t("Data Options")}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-column">
                                  <nav className="data-menu nav">
                                    <div className="nav-item">
                                      <div className="d-flex align-items-center download-btn data-item">
                                        <div>
                                          <img
                                            src={
                                              this.props.themeColor === "light"
                                                ? dataoptions
                                                : dataoptionsw
                                            }
                                          />
                                        </div>
                                        <span className="text-uppercase ml-3">
                                          {t("Data Options")}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="nav-item">
                                      <div
                                        className="d-flex align-items-center download-btn data-item"
                                        onClick={() =>
                                          this.exportInterviewResults(false)
                                        }
                                      >
                                        <div>
                                          <img
                                            src={
                                              this.props.themeColor === "light"
                                                ? export_icon
                                                : export_iconw
                                            }
                                          />
                                        </div>
                                        <span className="text-uppercase ml-3">
                                          {t("Export Results")}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="nav-item">
                                      <div
                                        className="d-flex align-items-center download-btn data-item"
                                        onClick={() =>
                                          this.exportInterviewResults(true)
                                        }
                                      >
                                        <div>
                                          <img
                                            src={
                                              this.props.themeColor === "light"
                                                ? export_icon
                                                : export_iconw
                                            }
                                          />
                                        </div>
                                        <span className="text-uppercase ml-3">
                                          {t("Export All Results")}
                                        </span>
                                      </div>
                                    </div>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item size={8}>
                  <div>
                    <Card className="card">
                      <CardHeader
                        title={
                          <div className="d-flex ">
                            <Grid item className="p-0" size={6}>
                              {t("Overall Risk Filter")}
                            </Grid>
                            <Grid item className="p-0" size={6}>
                              {" "}
                              {t("Sub Filter")}
                            </Grid>
                          </div>
                        }
                      ></CardHeader>
                      <CardContent>
                        <div className="d-flex ">
                          <Grid item className="p-0" size={6}>
                            <div className="d-flex">
                              <Grid item className="p-0" size={6}>
                                <Checkboxes
                                  checked={this.state.filterriskHigh}
                                  onChange={(event) =>
                                    this.handlefilterriskHighChange(event)
                                  }
                                  label={
                                    <FilterLabel
                                      label={t("High")}
                                      initial="H"
                                      color="HR"
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item className="p-0" size={6}>
                                <Checkboxes
                                  label={
                                    <FilterLabel
                                      label={t("Average")}
                                      initial="A"
                                      color="AR"
                                    />
                                  }
                                  checked={this.state.filterriskAverage}
                                  onChange={(event) =>
                                    this.handlefilterriskAverageChange(event)
                                  }
                                />
                              </Grid>
                            </div>
                            <div className="d-flex">
                              <Grid item className="p-0" size={6}>
                                <Checkboxes
                                  label={
                                    <FilterLabel
                                      label={t("Potential")}
                                      initial="P"
                                      color="PR"
                                    />
                                  }
                                  checked={this.state.filterriskPotential}
                                  onChange={(event) =>
                                    this.handlefilterriskPotentialChange(event)
                                  }
                                />
                              </Grid>
                              <Grid item className="p-0" size={6}>
                                <Checkboxes
                                  label={
                                    <FilterLabel
                                      label={t("Low")}
                                      initial="L"
                                      color="LR"
                                    />
                                  }
                                  checked={this.state.filterriskLow}
                                  onChange={(event) =>
                                    this.handlefilterriskLowChange(event)
                                  }
                                />
                              </Grid>
                            </div>
                            <div className="d-flex legend_tooltip">
                              <Grid item className="p-0" size={12}>
                                <Checkboxes
                                  label={
                                    <CustomTooltip
                                      placement="bottom"
                                      title="Participant's response(s) could not be evaluated."
                                    >
                                      <span>
                                        <FilterLabel
                                          label={t("Non-Compliant")}
                                          initial="NC"
                                          color="NC"
                                        />
                                      </span>
                                    </CustomTooltip>
                                  }
                                  checked={this.state.filterOverallriskNcAd}
                                  onChange={(event) =>
                                    this.handlefilterOverallriskNcAd(event)
                                  }
                                />
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item className="p-0 legend_tooltip" size={6}>
                            <div className="d-flex">
                              <Grid item className="p-0" size={9}>
                                <Grid item className="">
                                  <Checkboxes
                                    label={
                                      <CustomTooltip
                                        placement="bottom"
                                        title="Response was identified as a potential countermeasure."
                                      >
                                        <span>
                                          <FilterLabel
                                            label={t("Counter Measure")}
                                            initial="CM"
                                            color="CM"
                                          />
                                        </span>
                                      </CustomTooltip>
                                    }
                                    checked={this.state.filterriskCm}
                                    onChange={(event) =>
                                      this.handlefilterriskCmChange(event)
                                    }
                                  />
                                </Grid>
                                <Grid item className="">
                                  <Checkboxes
                                    label={
                                      <CustomTooltip
                                        title='Participant answered "Yes" to a pertinent question'
                                        placement="bottom"
                                      >
                                        <span>
                                          <FilterLabel
                                            label={t("Admission")}
                                            initial="AD"
                                            color="AD"
                                          />
                                        </span>
                                      </CustomTooltip>
                                    }
                                    checked={this.state.filterriskAd}
                                    onChange={(event) =>
                                      this.handlefilterriskAdChange(event)
                                    }
                                  />
                                </Grid>
                              </Grid>

                              <Grid item className="p-0" size={3}>
                                <Button
                                  variant="primary"
                                  ref=""
                                  onClick={(e) =>
                                    this.handlefilterApplyChange()
                                  }
                                  disabled={this.state.dateErrorMsg}
                                  className="apply-btn text-uppercase btn-sm-text float-right"
                                >
                                  {t("Apply")}
                                </Button>
                              </Grid>
                            </div>
                          </Grid>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item size={4}>
                  <div>
                    <Card>
                      <CardHeader
                        title={
                          <Grid container>
                            <Grid item size={3} className="p-0 pl-3">
                              {t("Search")}
                            </Grid>
                            <Grid item size={9} className="p-0 pr-3">
                              {this.state.isinternal.indexOf(
                                "tenant.project.resultshold.internal.read"
                              ) >= 0 ? (
                                <div className="d-flex float-right">
                                  <Checkboxes
                                    checked={this.state.onHoldCheck}
                                    label={t("On Hold")}
                                    onChange={(event) =>
                                      this.handleOnholdChange(event)
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        }
                      ></CardHeader>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item size={8} className="position-relative">
                            <input
                              className="form-control"
                              type="text"
                              placeholder={t("Search IRN and Name")}
                              onChange={this.searchResultsByText}
                            />
                          </Grid>

                          {this.state.isinternal.indexOf(
                            "tenant.project.resultshold.internal.read"
                          ) >= 0 ||
                          this.state.isinternal.indexOf(
                            "tenant.project.resultshold.internal.update"
                          ) >= 0 ? (
                            <Grid item size={4}>
                              <Button
                                ref=""
                                onClick={(e) => this.handleReleaseClick()}
                                className="mui-button success-button"
                              >
                                {t("Release")}
                              </Button>
                              <Button
                                ref=""
                                onClick={(e) => this.handleDeleteClick()}
                                className="mui-button delete-button"
                              >
                                {t("Delete")}
                              </Button>
                            </Grid>
                          ) : (
                            <Grid item size={4}>
                              {" "}
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item size={12}>
                  <AlertDismissible
                    msg={this.state.alertMsg}
                    variantType={this.state.variant}
                    show={this.state.alertStatus}
                    close={this.handleAlertClose}
                  ></AlertDismissible>
                </Grid>
                {this.state.isinternal != undefined &&
                this.state.isinternal.indexOf("tenant.project.results.read") >=
                  0 ? (
                  <React.Fragment>
                    <Grid item size={12}>
                      <div className="table_wrapper custom_table_container app-table report-result-module-table">
                        <CustomGrid
                          columns={tmdbData.columns}
                          data={tmdbData.rows}
                          currentPage={this.state.currentPage}
                          perPageCount={this.state.perPageCount}
                          totalPages={this.state.totalResults}
                          loading={this.state.loading}
                          onChangeRecords={this.onChangeRecords}
                          isServerPagination={true}
                          isServerSorting={true}
                          onSortModelChange={this.onColumnSort}
                        />
                      </div>
                    </Grid>
                  </React.Fragment>
                ) : (
                  this.state.noPermissionmsg
                )}
              </Grid>
              <ModalPopUp
                onHide={this.handleModalClose}
                title={t("Upload Results")}
                show={this.state.uploadResultsModalShow}
              >
                <p>{this.state.uploadResultsModalMsg}</p>
              </ModalPopUp>
              <ModalPopUp
                onHide={this.handleMitigationModalClose}
                title={t("Outcome Tracking")}
                show={this.state.resultMitigationModalShow}
              >
                <Grid container>
                  <AlertDismissible
                    className="w-100"
                    msg={this.state.alertMsgmitigate}
                    variantType={this.state.variantmitigate}
                    show={this.state.alertStatusmitigate}
                    close={this.handleAlertClosemitigate}
                  ></AlertDismissible>
                </Grid>
                {this.state.finalMitigationStatus !== null ? (
                  <Grid container>
                    <div className="col-lg-12 text-right mb-3">
                      <span className="float-right badge-sm primary">
                        {this.getAttributeLabel(
                          this.state.language,
                          this.state.finalMitigationStatus
                        )}
                      </span>
                    </div>
                  </Grid>
                ) : (
                  ""
                )}
                {this.state.isinternal != undefined &&
                this.state.isinternal.indexOf("result.follow.up.note.add") >=
                  0 ? (
                  <React.Fragment>
                    <Grid container>
                      <div className="col-lg-4 ">
                        <label
                          htmlFor="actions"
                          className="text-uppercase required"
                        >
                          {t("Actions")}
                        </label>
                        <Select
                          name="actions"
                          className="select-style"
                          classNamePrefix="select"
                          value={this.state.mitigationActions.filter(
                            (option) =>
                              option.value === this.state.mitigationActionValue
                          )}
                          options={this.state.mitigationActions}
                          isClearable={true}
                          placeholder={t("Action")}
                          onChange={this.onMitigationActionUpdate}
                        />
                      </div>
                      <div className="col-lg-4 ">
                        <label
                          htmlFor="status"
                          className="text-uppercase required"
                        >
                          {t("Status")}
                        </label>
                        <Select
                          name="status"
                          className="select-style"
                          classNamePrefix="select"
                          value={this.state.mitigationStatus.filter(
                            (option) =>
                              option.value ===
                              this.state.mitigationVerifiedValue
                          )}
                          options={this.state.mitigationStatus}
                          isClearable={true}
                          placeholder={t("Verified")}
                          onChange={this.onMitigationVerifiedUpdate}
                        />
                      </div>
                      <div className="col-lg-4 ">
                        <label
                          htmlFor="resultMitigationDate"
                          className="text-uppercase required"
                        >
                          {t("Date")}
                        </label>
                        <div className="">
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            className="form-control w-100 "
                            name="resultMitigationDate"
                            onChange={(d) => {
                              this.setDatepickerValue(d);
                            }}
                            selected={this.state.resultMitigationDate}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid container>
                      <div className="form-group col-lg-12 mt-2">
                        <label
                          htmlFor="notes"
                          className="text-uppercase required"
                        >
                          {t("note")}
                        </label>
                        <textarea
                          name="notes"
                          onChange={this.onMitigationNotesUpdate}
                          value={this.state.mitigationNotes}
                          className="form-control"
                          rows="2"
                        ></textarea>
                      </div>
                    </Grid>
                    <Grid container>
                      <div className="form-group col-lg-12 mt-2">
                        <Button
                          variant="primary"
                          onClick={() => this.saveMitigationResults()}
                          className="text-uppercase float-right"
                        >
                          {t("Save")}
                        </Button>
                      </div>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <Grid container>
                  <div className="col-lg-12 mt-2">
                    {this.getMitigationHistory(
                      this.state.mitigationResponse,
                      this.state.language
                    )}
                  </div>
                </Grid>
                <Grid container></Grid>
              </ModalPopUp>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
export const FilterLabel = (props) => {
  return (
    <span
      className={`result-filter-label report-result-icon ${props.stylename}`}
    >
      <span className={`result-filter-icon_${props.color}`}>
        {props.initial}
      </span>
      {props.label}
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    projectList: state.projects.projectList,
    themeColor: state.theme.themeColor,
  };
};
export default withTranslation()(connect(mapStateToProps)(ReportsCallrecords));
