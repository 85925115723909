import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select from "react-select";
import { Card, CardContent, CardHeader, Grid2 as Grid } from "@mui/material";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as _ from "lodash";
import dataoptions from "../../../assets/icon/dataoptions.png";
import dataoptionsw from "../../../assets/icon/dataoptionsw.png";
import export_icon from "../../../assets/icon/export_icon.png";
import export_iconw from "../../../assets/icon/export_iconw.png";
import { Button } from "../../../components/Common";
import { filenameFromResponseHeader } from "../../../shared/utils";
import { formatDate } from "../../Utility/DatePickerFunctions";
import StandardDatePicker from "../../Utility/StandardDatePicker";
import { Send_Api_Date_Format } from "../../../config";
import { getlanguageIdFromcode } from "../../../Helpers";
import { sortByLabel } from "../../../Helpers/index";
import i18n from "../../../locale/i18n";
import { getlaguageAttribute } from "../../../Services/tenantService";
import {
  getFilterActivity,
  getTenantProjects,
  getuserExportLogData,
  getuserLogData,
} from "../../../Services/userServices";
import { CustomGrid } from "../../CustomTable";
import MultiSelectDropDown from "../../Utility/MultiSelectDropDown/MultiSelectDropDown";

class UsersLog extends React.Component {
  constructor(props) {
    super(props);
    const displayTImeZoneOption = require("../../../config/timezone.json");
    this.state = {
      userRecordsData: [],
      displayTImeZoneOption: displayTImeZoneOption,
      displayTimeZone: {
        label: "(GMT-8) Pacific Time (US & Canada)",
        value: "6",
      },
      displayTimeZoneOffset: "-08:00",
      reportStartDate: moment(new Date()).format(Send_Api_Date_Format),
      reportEndDate: moment(new Date()).format(Send_Api_Date_Format),
      start_event_time: new Date("2020-01-01"),
      end_event_time: moment(),
      loading: true,
      perPageCount: 10,
      totalPages: 0,
      currentPage: 0,
      rowData: null,
      show: false,
      selectedAcccountId: "",
      activity: "",
      searchText: "",
      dateErrorMsg: false,
      clipmsg: "",
      TenantName: "",
      userStatus: [],
      userStatusSelectedValue: [],
      accountTypeOptions: [],
      selectedAccountType: [],
      selectedAccountTypeValue: [],
    };
  }

  componentDidMount() {
    this.props.action("Users");
    getTenantProjects()
      .then((res) => {
        console.log("getTenantProjects", res);
        let tenantDropDown = res.data
          .map((option) => {
            return {
              value: option.tenantId,
              label: option.tenantName,
            };
          })
          .sort(sortByLabel);
        this.setState(
          {
            accountsDetails: res.data,
            tenantDropOption: tenantDropDown,
          },
          () => {
            let filters = this.getinitialData();
            this.getAuditRecords(filters);
          }
        );
      })
      .catch((err) => {});

    getFilterActivity()
      .then((res) => {
        let FilterDropDown = res.data
          .map((option) => {
            return {
              value: option,
              label: option,
            };
          })
          .sort(sortByLabel);
        this.setState(
          {
            filterDropOption: FilterDropDown,
          },
          () => {}
        );
      })
      .catch((err) => {});
    this.getAccountTypeOptions();
  }

  getAccountTypeOptions = () => {
    let languageCode = i18n.language;
    let languageId = getlanguageIdFromcode(languageCode);
    getlaguageAttribute(languageId)
      .then((res) => {
        let filterAccountTypeOptionsData = _.filter(res.data.tenantAttributes, {
          attributeType: "ACCOUNT_TYPE",
          active: true,
        }).map((option) => {
          return {
            value: option.attributeId,
            label: option.description,
          };
        });
        this.setState({
          accountTypeOptions: filterAccountTypeOptionsData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  searchResultsByText = (event) => {
    let text = event.target.value;
    this.setState(
      (preState) => {
        return { searchText: text };
      },
      () => {}
    );
  };

  getinitialData = () => {
    let initialdata = {
      from_date: moment(this.state.start_event_time).format(
        Send_Api_Date_Format
      ),
      to_date: moment(this.state.end_event_time).format(Send_Api_Date_Format),
      page_size: this.state.perPageCount,
      page: this.state.currentPage,
      displayTimeZone: this.state.displayTimeZoneOffset,
    };
    if (this.state.selectedAcccountId != "") {
      initialdata.tenant_id = this.state.selectedAcccountId;
    }
    if (this.state.searchText != "") {
      initialdata.search = this.state.searchText;
    }
    if (this.state.userStatus.length !== 0) {
      initialdata.user_status = this.state.userStatus;
    }
    if (this.state.selectedAccountType.length !== 0) {
      initialdata.account_type_id = this.state.selectedAccountType;
    }
    return initialdata;
  };

  setStartDatepickerValue = (d) => {
    this.setState(
      {
        start_event_time: d,
        reportStartDate: d,
      },
      () => this.dateCompare()
    );
  };

  setEndDatepickerValue = (d) => {
    this.setState(
      {
        end_event_time: moment(d),
        reportEndDate: d,
      },
      () => this.dateCompare()
    );
  };

  dateCompare = () => {
    let startTime = moment(this.state.start_event_time).unix();
    let endTime = moment(this.state.reportEndDate).endOf("day").unix();
    this.setState({
      dateErrorMsg: startTime > endTime ? true : false,
    });
  };

  onChangeTenant = (e) => {
    this.setState(
      {
        selectedAcccountId: e !== null ? e.value : "",
        selectedAcccountLabel: e !== null ? e.label : "",
        selectedProjectId: "",
        selectedProjectLabel: "",
        currentPage: 0,
        totalResults: 0,
        totalPages: 0,
        alertMsg: "",
        variant: "",
        alertStatus: false,
        loading: true,
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  onChangeUserStatus = (e) => {
    let userStatusValues = [];
    for (const userValue of e) {
      userStatusValues.push(userValue);
    }
    this.setState(
      {
        userStatus: userStatusValues,
        userStatusSelectedValue: e,
        currentPage: 0,
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  onChangeAccounttype = (e) => {
    let selectedOutputValues = [];
    for (const selectedValue of e) {
      selectedOutputValues.push(selectedValue);
    }
    this.setState(
      {
        selectedAccountType: selectedOutputValues,
        selectedAccountTypeValue: e,
        currentPage: 0,
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  handlefilterApplyChange = () => {
    this.setState(
      {
        loading: true,
        currentPage: 0,
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  onChangeRecords = (perPageCount) => {
    this.setState(
      {
        perPageCount: perPageCount.pageSize,
        currentPage: perPageCount.page,
        loading: true,
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  onColumnSort = (type, { sortField, sortOrder, data }) => {
    console.log(type, sortField, sortOrder, data);
    let Sortobeject = {};
    if (sortField == "interviewReferenceNumber")
      Sortobeject["irnno"] = sortOrder;
    if (sortField == "phoneCallDuration") Sortobeject["duration"] = sortOrder;
    this.setState(
      (preState) => {
        return {
          ...preState,
          sortOrder: Sortobeject,
          loading: false, // true when api integrate
        };
      },
      () => {}
    );
  };

  getAuditRecords = (filters) => {
    getuserLogData(filters)
      .then((res) => {
        this.setState({
          loading: false,
          userRecordsData: res.data.userRecords,
          totalPages: res.data.totalRecords,
        });
      })
      .catch((err) => {
        console.log("initial err", err);
      });
  };

  onChangeTimeZone = (option) => {
    const timearray = this.state.displayTImeZoneOption;
    let filterOffset =
      timearray != undefined &&
      timearray.filter(function (timearray) {
        return timearray.value === option.value;
      });
    this.setState(
      {
        displayTimeZone: option,
        displayTimeZoneOffset: filterOffset[0].offset,
      },
      () => {
        console.log("off set on timezone", this.state);
      }
    );
  };

  getAuditrecordsRows = (data) => {
    let row = data.map((userData, index) => {
      let create_ts =
        userData.create_ts != null
          ? moment
              .unix(userData.create_ts / 1000)
              .utcOffset(this.state.displayTimeZoneOffset)
              .format("MM/DD/YYYY HH:mm:ss")
          : "-";
      let login_ts =
        userData.login_ts != null
          ? moment
              .unix(userData.login_ts / 1000)
              .utcOffset(this.state.displayTimeZoneOffset)
              .format("MM/DD/YYYY HH:mm:ss")
          : "-";
      return {
        id: index + 1,
        accountName: userData.accountName,
        accountStatus: userData.accountStatus,
        accountType: userData.accountType,
        email: userData.email,
        create_ts: create_ts,
        login_ts: login_ts,
        roleName: userData.roleName,
        title: userData.title,
        userStatus: userData.userStatus,
        username: userData.username,
      };
    });
    return row;
  };

  onChangePage = (number) => {
    this.setState(
      (preState) => {
        return {
          currentPage: number,
          userRecordsData: [],
          loading: true,
        };
      },
      () => {
        let filters = this.getinitialData();
        this.getAuditRecords(filters);
      }
    );
  };

  exportUsersRecords = () => {
    let filters = this.getinitialData();
    getuserExportLogData(filters)
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv; charset=utf-8" });
        FileSaver.saveAs(
          blob,
          filenameFromResponseHeader(res.headers["content-disposition"])
        );
      })
      .catch((err) => {
        console.log("error in export users", err);
      });
  };

  render() {
    let { t } = this.props;
    let rows = this.getAuditrecordsRows(this.state.userRecordsData);
    const perpageOptions = [
      { value: "10", label: "10" },
      { value: "25", label: "25" },
      { value: "50", label: "50" },
      { value: "100", label: "100" },
    ];
    const userStatusOption = [
      {
        value: "Inactive",
        label: t("Inactive"),
      },
      {
        value: "Active",
        label: t("Active"),
      },
    ];

    const columns = [
      {
        field: "username",
        headerName: t("User Name"),
        width: 200,
        sortable: false,
        filterable: false,
      },
      {
        field: "roleName",
        headerName: t("Role"),
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: "title",
        headerName: t("Title"),
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: "email",
        headerName: t("User Email"),
        width: 300,
        sortable: false,
        filterable: false,
      },
      {
        field: "userStatus",
        headerName: t("User Status"),
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: "login_ts",
        headerName: t("Last Login"),
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: "accountType",
        headerName: t("Account Type"),
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: "accountStatus",
        headerName: t("Account Status"),
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: "accountName",
        headerName: t("Customer Name"),
        width: 200,
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <div className="w-1200">
        <h6 className="account-labels row">
          <Grid item size={12}>
            <span className="float-right ml-4 badge badge-sm badge-primary">
              {this.state.selectedAcccountLabel != undefined &&
              this.state.selectedAcccountLabel != ""
                ? `Account : ${this.state.selectedAcccountLabel}`
                : ""}
            </span>
          </Grid>
        </h6>
        <Grid container className="participants-page">
          <Grid item size={12}>
            <div className="">
              <Card>
                <CardHeader
                  title={
                    <Grid container>
                      <div className="col-lg-2"> {t("Accounts Options")}</div>
                      <div className="col-lg-2"> {t("User Status")}</div>
                      <div className="col-lg-2"> {t("Display Options")}</div>
                      <div className="col-lg-4">
                        {t("Filter by Date Range")}
                      </div>
                      <div className="col-lg-2"> {t("Table Options")}</div>
                    </Grid>
                  }
                ></CardHeader>
                <CardContent
                  className="resultsearchindex"
                  style={{
                    position: "relative",
                    zIndex: "999",
                  }}
                >
                  <Grid container>
                    <div className="position-relative col-lg-2">
                      <Select
                        name="status"
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.tenantDropOption}
                        isClearable={true}
                        placeholder={t("Select Account")}
                        onChange={this.onChangeTenant}
                      />
                    </div>
                    <div className="filter_project_row filter__users__dropdown position-relative col-lg-2">
                      <MultiSelectDropDown
                        options={userStatusOption}
                        setSelectedOption={(e) => this.onChangeUserStatus(e)}
                        value={this.state.userStatusSelectedValue}
                        placeholder={t("Select Status")}
                      />
                    </div>
                    <div className="position-relative col-lg-2">
                      <Select
                        name="location"
                        className="select-style"
                        classNamePrefix="select"
                        options={this.state.displayTImeZoneOption}
                        isClearable={false}
                        value={this.state.displayTimeZone}
                        placeholder={t("Select Timezone")}
                        onChange={(option) => this.onChangeTimeZone(option)}
                      />
                    </div>
                    <div className="position-relative col-lg-4">
                      <Grid container>
                        <div
                          className="position-relative col-lg-6"
                          style={{ zIndex: "99" }}
                        >
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            placeholderText="Start Date"
                            className="form-control w-100 "
                            autoComplete="off"
                            name="start_event_time"
                            onChange={(d) => {
                              this.setStartDatepickerValue(d);
                            }}
                            selected={this.state.start_event_time}
                            label={t("From")}
                          />
                        </div>
                        <div
                          className="position-relative col-lg-6"
                          style={{ zIndex: "99" }}
                        >
                          <StandardDatePicker
                            dateFormat={formatDate()}
                            placeholderText="End Date"
                            className="form-control w-100 "
                            autoComplete="off"
                            name="reportEndDate"
                            onChange={(e, d) => {
                              this.setEndDatepickerValue(e, d);
                            }}
                            selected={this.state.reportEndDate}
                            label={t("To")}
                          />
                        </div>
                      </Grid>
                      {this.state.dateErrorMsg ? (
                        <span className="input-feedback">
                          End Date must be greater than Start Date
                        </span>
                      ) : null}
                    </div>
                    <div className="position-relative col-lg-2">
                      <div className="position-relative">
                        <div className="data-option-container">
                          <div className="data-control">
                            <div className="d-flex align-items-center download-btn">
                              <div>
                                <img
                                  src={
                                    this.props.themeColor === "light"
                                      ? dataoptions
                                      : dataoptionsw
                                  }
                                />
                              </div>
                              <span className="text-uppercase ml-3">
                                {t("Data Options")}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <nav className="data-menu nav">
                              <div className="nav-item">
                                <div className="d-flex align-items-center download-btn data-item">
                                  <div>
                                    <img
                                      src={
                                        this.props.themeColor === "light"
                                          ? dataoptions
                                          : dataoptionsw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase ml-3">
                                    {t("Data Options")}
                                  </span>
                                </div>
                              </div>
                              <div className="nav-item">
                                <div
                                  className="d-flex align-items-center download-btn data-item"
                                  onClick={() => this.exportUsersRecords()}
                                >
                                  <div>
                                    <img
                                      src={
                                        this.props.themeColor === "light"
                                          ? export_icon
                                          : export_iconw
                                      }
                                    />
                                  </div>
                                  <span className="text-uppercase ml-3">
                                    {t("Export Records")}
                                  </span>
                                </div>
                              </div>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid item size={12}>
            <div className="">
              <Card>
                <CardHeader
                  title={
                    <Grid container>
                      <div className="col-lg-3"> {t("Search For Email")}</div>
                      <div className="col-lg-3"> {t("Account Type")}</div>
                    </Grid>
                  }
                ></CardHeader>
                <CardContent>
                  <Grid container className="align-items-end">
                    <div className="position-relative col-lg-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("Search")}
                        onChange={this.searchResultsByText}
                      />
                    </div>
                    <div className="filter_project_row filter__users__dropdown position-relative col-lg-3">
                      <MultiSelectDropDown
                        options={this.state.accountTypeOptions}
                        setSelectedOption={(e) => this.onChangeAccounttype(e)}
                        value={this.state.selectedAccountTypeValue}
                        placeholder={t("Select Account Type")}
                      />
                    </div>
                    <div className="position-relative col-lg-1">
                      <Button
                        variant="primary"
                        ref=""
                        onClick={(e) => this.handlefilterApplyChange()}
                        className="text-uppercase btn-sm-text float-right"
                        disabled={this.state.dateErrorMsg}
                      >
                        {t("Apply")}
                      </Button>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item size={12}>
            <Card>
              <div className="table_wrapper custom_table_container app-table report-users-module-table">
                <CustomGrid
                  columns={columns}
                  data={rows}
                  loading={this.state.loading}
                  onChangeRecords={this.onChangeRecords}
                  perPageCount={this.state.perPageCount}
                  currentPage={this.state.currentPage}
                  totalPages={this.state.totalPages}
                  isServerPagination={true}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
  };
};
export default withTranslation()(connect(mapStateToProps)(UsersLog));
