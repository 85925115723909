import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  ModalPopUp,
} from "../../../components/Common";
import {
  assignProjectTeam,
  getProjectRoles,
  getProjectTeam,
  getTenantTeam,
  unassignProjectTeam,
} from "../../../Services/projectServices";
import { IsConfirm } from "../../Utility/ModalPopUp";
import "./projectteam.css";

class ProjectTeams extends Component {
  constructor(props) {
    super(props);
    this.assignButtonRef = createRef();
    // Don't call this.setState() here!
    this.state = {
      assignedUserId: "",
      assignedUserRole: "",
      assignedProjectRole: "",
      isChecked: false,
      projectId: "",
      projectteamDetail: [],
      tenantTeam: [],
      teammodalShow: false,
      rolesDropdown: [],
      userAssign: [],
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isinternal: [],
      noPermissionmsg: "Loading...",
      userInfostate: JSON.parse(localStorage.getItem("userInfo")),
      isConfirm: false,
    };
  }

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };
  unassignTeamMember = (id) => {
    let unassignObj = {
      tenantId: this.state.userInfostate.tenantId,
      users: [id],
    };
    let projectId = this.state.projectId;
    unassignProjectTeam(unassignObj, projectId)
      .then((res) => {
        const newtarray1 = this.state.projectteamDetail.filter(function (obj) {
          return obj.userId !== id;
        });
        this.setState({
          alertMsg: this.props.t("User removed from team sucessfully"),
          variant: "success",
          alertStatus: true,
          projectteamDetail: newtarray1,
          isConfirm: false,
        });
        this.getUpdatedTeam(projectId);
      })
      .catch((err) => {
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          isConfirm: false,
        });
      });
  };

  handleModalLaunch = () => {
    this.setState({
      teammodalShow: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      teammodalShow: false,
      assignedProjectRole: "",
      assignedUserId: "",
      assignedUserRole: "",
    });
  };
  assignTeamMembers = () => {
    let projectId = this.state.projectId;
    if (
      this.state.assignedProjectRole !== "" &&
      this.state.assignedUserId !== ""
    ) {
      if (this.assignButtonRef.current) {
        this.assignButtonRef?.current.setAttribute("disabled", "disabled");
      }
      let newdata2 = {};
      newdata2 = {
        userId: this.state.assignedUserId,
        roleId: this.state.assignedProjectRole,
        id:
          this.state.assignedUserRole == undefined
            ? null
            : this.state.assignedUserRole,
      };
      let userAssignObj = {
        tenantId: this.state.userInfostate.tenantId,
        users: [newdata2],
      };
      assignProjectTeam(userAssignObj, projectId)
        .then((res) => {
          if (this.assignButtonRef?.current) {
            this.assignButtonRef?.current.removeAttribute("disabled");
          }

          this.setState({
            alertMsg: this.props.t("User assigned to team sucessfully"),
            variant: "success",
            alertStatus: true,
            teammodalShow: false,
          });
          this.getUpdatedTeam(projectId);
        })
        .catch((err) => {
          if (this.assignButtonRef?.current) {
            this.assignButtonRef?.current.removeAttribute("disabled");
          }

          let errMsg = err?.response?.data?.message;
          this.setState({
            alertMsg: errMsg,
            variant: "danger",
            alertStatus: true,
          });
        });
    } else {
      if (this.assignButtonRef?.current) {
        this.assignButtonRef?.current.removeAttribute("disabled");
      }
      this.setState({
        alertMsg: this.props.t("Please select User and Project role to assign"),
        variant: "danger",
        alertStatus: true,
      });
    }
  };
  handleRolesChange = (value, userId, userrole) => {
    this.setState({
      assignedProjectRole: value.value,
    });
  };

  handleAssignedUserChange = (event, id) => {
    const value = event.target.value;
    this.setState({
      assignedUserId: value,
      assignedUserRole: id,
    });
  };
  getUpdatedTeam = (projectId) => {
    getProjectTeam(projectId)
      .then((res) => {
        this.setState({
          projectteamDetail: res.data.team,
          projectId: projectId,
        });
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
        });
      });

    getTenantTeam(projectId)
      .then((res) => {
        this.setState({
          tenantTeam: res.data.users,
        });
      })
      .catch((err) => {
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
        });
      });
  };
  getloggedinUser = (userId, defaultuser) => {
    const loggedinId = this.state.userInfostate.id;
    return !!(loggedinId == userId || defaultuser);
  };

  onConfirmBox = (id) => {
    this.setState({ isConfirm: id });
  };

  handleConfirm = (e) => {
    if (e !== false) {
      this.unassignTeamMember(this.state.isConfirm);
    }
    this.setState({ isConfirm: false });
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.isinternal !== nextProps.statedata.isinternal) {
      this.setState({
        isinternal: nextProps.statedata.isinternal,
        noPermissionmsg: "",
      });
    }
  }

  componentDidMount() {
    const self = this;
    const id = this.props.match.params.id;
    this.getUpdatedTeam(id);
    getProjectRoles(1)
      .then((res) => {
        self.setState({
          rolesDropdown: res.data,
        });
      })
      .catch((err) => {
        let errMsg = err?.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
        });
      });
  }

  render() {
    const { t, statedata } = this.props;

    let selectRolesDropdown = this.state.rolesDropdown.map((option) => {
      return {
        value: option.roleId,
        label: option.name,
      };
    });
    let TeamData =
      this.state.projectteamDetail.length > 0
        ? this.state.projectteamDetail.map((team, index) => {
            return (
              <tr key={team.userId}>
                <td>
                  {team.image !== null ? (
                    <span
                      className="team-image-container"
                      style={{
                        backgroundImage: `url(data:image/png;base64,${team.image})`,
                      }}
                    ></span>
                  ) : (
                    <p
                      className="profilename"
                      data-letters={
                        team.firstName !== null
                          ? `${team.firstName
                              .substring(0, 1)
                              .toUpperCase()}${team.lastName
                              .substring(0, 1)
                              .toUpperCase()}`
                          : ``
                      }
                    >
                      {" "}
                    </p>
                  )}
                </td>
                <td>
                  {team.firstName} {team.lastName}
                </td>
                <td>{team.title}</td>
                <td>
                  {team.roleId != null && (
                    <span>
                      {team.roleName}
                      {}
                    </span>
                  )}
                </td>
                <td>
                  {!this.getloggedinUser(team.userId, team.isDefaultUser) &&
                  this.props.statedata.isinternal.indexOf(
                    "tenant.project.team.delete"
                  ) >= 0 ? (
                    <Button
                      variant="secondary"
                      className="btn-rounded btn-sm mt-1 float-right"
                      onClick={(e) => this.onConfirmBox(team.userId)}
                      iconButton={true}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="button_icon_only"
                      />
                    </Button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })
        : "No team found";

    let TenanteamData = this.state.tenantTeam.map((team, index) => {
      return team.isDefaultUser == false || team.isDefaultUser == null ? (
        <tr key={team.userId}>
          <td>
            <label className="custom-radio mb-3">
              <input
                type="radio"
                value={team.userId}
                name={"assigned_userId"}
                onChange={(event) =>
                  this.handleAssignedUserChange(event, team.id)
                }
              />
              <span className="checkmark"></span>
            </label>
          </td>
          <td>{team.roleId != null ? team.roleName : ""}</td>
          <td>
            {team.firstName} {team.lastName}
          </td>
          <td>{team.title}</td>
        </tr>
      ) : (
        ""
      );
    });

    return statedata.isinternal.length > 0 &&
      statedata.isinternal.indexOf("tenant.project.team.read") >= 0 ? (
      <React.Fragment>
        <div className="w-1200">
          <Card>
            <CardContent>
              <Grid container>
                <Grid item size={9}>
                  <h6 className="page-title">{t("Team Directory")} </h6>
                </Grid>
                <Grid item size={3}>
                  {statedata.isinternal.indexOf("tenant.project.team.update") >=
                  0 ? (
                    <Button
                      variant="success"
                      className="btn-rounded btn-sm float-right"
                      onClick={() => this.handleModalLaunch()}
                      iconButton={true}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="button_icon_only"
                      />
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <AlertDismissible
            msg={this.state.alertMsg}
            variantType={this.state.variant}
            show={this.state.alertStatus}
            close={this.handleAlertClose}
          ></AlertDismissible>
          <div className="table_wrapper">
            <div className="react-bootstrap-table">
              <table className="table">
                <thead>
                  <tr>
                    <th> </th>
                    <th>{t("Name")} </th>
                    <th>{t("Title")}</th>
                    <th>{t("Role")}</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {TeamData}
                  {/* <tr>
                        <td><img src={defaultTeamImg} style={{margin: '0px 0px 0px 30px', width: "3.75em", height:"3.75em"}} width="40" height="40" alt="default Team Img"/></td><td>Clearspeed  </td><td> Clearspeed Team </td><td><span>Support</span></td><td> </td>
                    </tr>  */}
                </tbody>
              </table>
            </div>
          </div>
          <ModalPopUp
            onHide={this.handleModalClose}
            title={"Assign Roles"}
            show={this.state.teammodalShow}
          >
            <Grid container className="mb-2">
              {" "}
              <Grid item size={4}>
                {" "}
                <Select
                  name="userrole"
                  options={selectRolesDropdown}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(value) => this.handleRolesChange(value)}
                  isSearchable={false}
                />
              </Grid>
            </Grid>
            <AlertDismissible
              msg={this.state.alertMsg}
              variantType={this.state.variant}
              show={this.state.alertStatus}
              close={this.handleAlertClose}
            ></AlertDismissible>

            <div className="table_wrapper_modal mt-4 mb-4">
              <table className="table-fixed table">
                <thead>
                  <tr>
                    <th width="5%"></th>
                    <th width="30%">
                      {t("Projects")} {t("Role")}{" "}
                    </th>
                    <th width="35%">{t("Name")}</th>
                    <th width="30%">{t("Title")}</th>
                  </tr>
                </thead>
                <tbody>{TenanteamData}</tbody>
              </table>
            </div>
            <Grid container>
              <Grid item size={12} className="text-center">
                <Button
                  ref={this.assignButtonRef}
                  variant="primary"
                  onClick={() => this.assignTeamMembers()}
                >
                  {t("Save")}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => this.handleModalClose()}
                >
                  {t("Cancel")}
                </Button>
              </Grid>
            </Grid>
          </ModalPopUp>
          <IsConfirm
            show={this.state.isConfirm}
            t={this.props.t}
            onOkay={(e) => this.handleConfirm(e)}
          >
            {" "}
            {t("Do you really want to remove this member from team?")}
          </IsConfirm>
        </div>
      </React.Fragment>
    ) : (
      this.state.noPermissionmsg
    );
  }
}
export default withTranslation()(ProjectTeams);
