import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Field } from "formik";
import { Grid2 as Grid } from "@mui/material";
import { getDaysOptions } from "../../../shared/utils";
import { SelectField } from "../Helper";
import DropDownOptions from "../ProjectInitialDetail/DropDownOptions";
import InternalUseOnlyOption from "./InternalUseOnlyOption";

const InternalUseOnly = (props) => {
  const { t } = useTranslation();

  const fileInput = useRef(null);

  /* const "invalidImage" show upload image status */
  const [invalidImage, setInvalidImage] = useState(null);

  const [showConfigurationJson, setShowConfigurationJson] = useState("");

  const [disabledUploadJsonBtn, setDisabledUploadJsonBtn] = useState(false);

  const { formik, state } = props;

  const {
    handleBlur,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const { templateTypeOption } = DropDownOptions(state, t);

  const {
    interviewOption,
    acessCodeOption,
    guideURLOption,
    integrationMethodOption,
    multilingualPhoneOption,
  } = InternalUseOnlyOption(state);
  console.log("state", state);

  let internalFieldDisabled =
    state.isinternal.indexOf("tenant.project.detail.internal.update") >= 0
      ? true
      : false;

  /**
   * THis function will upload Json file
   * @param {object} event
   * @param {function} setFieldValue
   * @returns
   */

  const onChangeHandler = (event, setFieldValue) => {
    const imageFile = event.target.files[0];
    if (!imageFile) {
      setInvalidImage("Please select Json File");
      return false;
    } else {
      setDisabledUploadJsonBtn(true);
      const reader = new FileReader();
      reader.onload = () => {
        let data = reader.result.substring(0, 20000000);
        setShowConfigurationJson(data);
        let response = JSON.parse(data);
        let jsonresponse = JSON.stringify(response);
        console.log("jsonresponse", jsonresponse);
        if (data) {
          setDisabledUploadJsonBtn(false);
          try {
            setFieldValue("configurationJSON", jsonresponse);
            setInvalidImage("");
          } catch (e) {
            if (e instanceof SyntaxError) {
              setInvalidImage(
                "JSON is not valid. Upload again after correction"
              );
              fileInput.current.value = "";
            } else {
              console.log(e, false);
            }
          }
        }
      };
      reader.readAsText(event.target.files[0]);
    }
  };

  return (
    <Grid item size={12}>
      <div className="mb-2 mt-2 additional_configuration_accordion">
        <Accordion
          allowZeroExpanded="true"
          allowMultipleExpanded="true"
          preExpanded={["a"]}
        >
          <AccordionItem uuid="a">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header">
                  <div>
                    <label className="row-header-title">
                      {t("Internal Use Only")}
                    </label>
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Grid
                container
                className="internal_view_old pt-2"
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item size={3}>
                  <div className="form-group">
                    <label htmlFor="defaultCustomerInterviewMethodId">
                      {t("Delivery Method")}
                    </label>
                    <Field
                      name={"defaultCustomerInterviewMethodId"}
                      onBlur={handleBlur}
                      component={SelectField}
                      options={interviewOption}
                      values={values.defaultCustomerInterviewMethodId}
                    />
                  </div>
                </Grid>
                <Grid item size={3}>
                  <div className="form-group">
                    <label htmlFor="multilingualPhoneId">
                      {t("Multilingual Phone")}
                    </label>
                    <Field
                      name={"multilingualPhoneId"}
                      onBlur={handleBlur}
                      component={SelectField}
                      options={multilingualPhoneOption}
                      values={values.multilingualPhoneId}
                      isDisabled={!internalFieldDisabled}
                    />
                  </div>
                </Grid>

                <Grid item size={3}>
                  <div className="form-group">
                    <label htmlFor="Integration Method">
                      {t("Integration Method")}
                    </label>
                    <Field
                      name={"integration_method_id"}
                      onBlur={handleBlur}
                      component={SelectField}
                      options={integrationMethodOption}
                      values={values.integration_method_id}
                    />
                  </div>
                </Grid>
                <Grid item size={3}>
                  <div className="form-group">
                    <label htmlFor="accessCodeBehaviorId">
                      {t("Access Code Behaviour")}
                    </label>
                    <Field
                      name={"accessCodeBehaviorId"}
                      onBlur={handleBlur}
                      component={SelectField}
                      options={acessCodeOption}
                      values={values.accessCodeBehaviorId}
                    />
                  </div>
                </Grid>
                <Grid item size={6}>
                  <div className="form-group">
                    <label htmlFor="file">
                      {t("Upload Configuration JSON")}
                    </label>
                    <div className="custom-upload">
                      <input
                        id="configurationfile"
                        name="file"
                        ref={fileInput}
                        accept=".json"
                        type="file"
                        className="configuration-file-input"
                        disabled={disabledUploadJsonBtn}
                        data-content={t("Upload JSON")}
                        onChange={(e) => onChangeHandler(e, setFieldValue)}
                      />
                    </div>
                    {invalidImage && (
                      <p className="input-feedback">{invalidImage}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <textarea
                      rows="4"
                      disabled
                      value={showConfigurationJson}
                      className="form-control"
                      readOnly
                    />
                  </div>
                </Grid>
                <Grid item size={6}>
                  <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item size={6}>
                      <div className="input-group mb-3 ">
                        <div className="d-block w-100">
                          <label htmlFor="IRN Length">{t("IRN Length")}</label>
                        </div>
                        <input
                          type="number"
                          name="irnLength"
                          min="6"
                          max="15"
                          className={
                            "form-control" +
                            (errors.irnLength && touched.irnLength
                              ? " is-invalid"
                              : "")
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.irnLength}
                        />
                        <div className="d-block w-100">
                          {errors.irnLength && touched.irnLength && (
                            <div className="input-feedback">
                              {errors.irnLength}
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item size={6}>
                      <div className="form-group">
                        <label htmlFor="guideURLOptionId">
                          {t("Guide Domain URL")}
                        </label>

                        <Field
                          name={"guideURLOptionId"}
                          onBlur={handleBlur}
                          component={SelectField}
                          options={guideURLOption}
                          values={values.guideURLOptionId}
                          isDisabled={!internalFieldDisabled}
                        />
                      </div>
                    </Grid>
                    <Grid item size={6}>
                      <div className="form-group">
                        <label htmlFor="sender_name">
                          {t("Email Sender Name")}
                        </label>
                        <Field
                          name="sender_name"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          values={values.sender_name}
                        />
                        {errors.sender_name && touched.sender_name && (
                          <div className="input-feedback">
                            {errors.sender_name}
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item size={6}>
                      <div className="form-group">
                        <label htmlFor="Email Recipients">
                          {t("Email Recipients")}
                        </label>
                        <Field
                          name="result_email_recipient"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          values={values.result_email_recipient}
                        />
                        {errors.result_email_recipient &&
                          touched.result_email_recipient && (
                            <div className="input-feedback">
                              {errors.result_email_recipient}
                            </div>
                          )}
                      </div>
                    </Grid>
                    <Grid item size={6}>
                      <div className="form-group">
                        <label htmlFor="result_configuration_template_id">
                          {t("Template Type")}
                        </label>

                        <Field
                          name={"result_configuration_template_id"}
                          id="result_configuration_template_id"
                          onBlur={handleBlur}
                          component={SelectField}
                          options={templateTypeOption}
                          values={values.result_configuration_template_id}
                          hasClearable={true}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item size={12}>
                  <div className="label-withline">
                    <h5 className="label-withline_text">
                      {t("Enable/Disable Features")}
                    </h5>
                    <div className="d-flex mt-3">
                      <Grid item size={4}>
                        <h6>{t("Result Features")}</h6>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            name="result_auto_release"
                            checked={values.result_auto_release}
                            className="form-check-input"
                            id="result_auto_release"
                          />
                          <label
                            htmlFor="result_auto_release"
                            className="form-check-label"
                          >
                            {t("Result Auto Release")}
                          </label>
                        </div>
                        <div className="form-check mt-1 mb-2">
                          <Field
                            type="checkbox"
                            name="mask_overall_result_to_HR_for_AD"
                            id="mask_overall_result_to_HR_for_AD"
                            checked={values.mask_overall_result_to_HR_for_AD}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="mask_overall_result_to_HR_for_AD"
                            className="form-check-label"
                          >
                            {t("AD equals HR overall")}
                          </label>
                        </div>
                        <div className="form-check mt-1 mb-2">
                          <Field
                            type="checkbox"
                            name="mask_overall_result_to_HR_for_CM"
                            id="mask_overall_result_to_HR_for_CM"
                            checked={values.mask_overall_result_to_HR_for_CM}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="mask_overall_result_to_HR_for_CM"
                            className="form-check-label"
                          >
                            {t("CM equals HR overall")}
                          </label>
                        </div>
                        <div className="form-check mt-1 mb-2">
                          <Field
                            type="checkbox"
                            id="mask_overall_result_to_HR_for_NC"
                            name="mask_overall_result_to_HR_for_NC"
                            checked={values.mask_overall_result_to_HR_for_NC}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="mask_overall_result_to_HR_for_NC"
                            className="form-check-label"
                          >
                            {t("NC equals HR overall")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="allowIswCreateParticipant"
                            name="allow_isw_create_participant"
                            checked={values.allow_isw_create_participant}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="allowIswCreateParticipant"
                            className="form-check-label"
                          >
                            {t("Allow ISW Create Participant")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="makePartialNcAsCompleteNc"
                            name="make_partial_nc_as_complete_nc"
                            checked={values.make_partial_nc_as_complete_nc}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="makePartialNcAsCompleteNc"
                            className="form-check-label"
                          >
                            {t("Make Partial NC as Overall NC")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="review_unreview_configuration"
                            name="review_unreview_configuration"
                            checked={values.review_unreview_configuration}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="review_unreview_configuration"
                            className="form-check-label"
                          >
                            {t("MARK_RESULT_AS_REVIEWED")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="cm_recordings_configuration"
                            name="cm_recordings_configuration"
                            checked={values.cm_recordings_configuration}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="cm_recordings_configuration"
                            className="form-check-label"
                          >
                            {t("SHOW_RECORDING")}
                          </label>
                        </div>
                      </Grid>
                      <Grid item size={4}>
                        <h6>{t("Communication Features")}</h6>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="invite_on_participant_create"
                            name="invite_on_participant_create"
                            checked={values.invite_on_participant_create}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="invite_on_participant_create"
                            className="form-check-label"
                          >
                            {t("Email Invite on create participant")}
                          </label>
                        </div>

                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="sms_invite_on_participant_create"
                            name="sms_invite_on_participant_create"
                            checked={values.sms_invite_on_participant_create}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="sms_invite_on_participant_create"
                            className="form-check-label"
                          >
                            {t("Sms Invite on create participant")}
                          </label>
                        </div>

                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enableSMSCommunication"
                            name="enableSMSCommunication"
                            checked={values.enableSMSCommunication}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enableSMSCommunication"
                            className="form-check-label"
                          >
                            {t("Send SMS")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enable_refresh_accesscode"
                            name="enable_refresh_accesscode"
                            checked={values.enable_refresh_accesscode}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enable_refresh_accesscode"
                            className="form-check-label"
                          >
                            {t("Refresh Access Code")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enableEmailCommunication"
                            name="enableEmailCommunication"
                            checked={values.enableEmailCommunication}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enableEmailCommunication"
                            className="form-check-label"
                          >
                            {t("E-Mail Communication")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enable_indivisual_result_email"
                            name="enable_indivisual_result_email"
                            checked={values.enable_indivisual_result_email}
                            className="form-check-input"
                            onChange={(e) => {
                              setFieldTouched("result_email_recipient", true);
                              setFieldValue(
                                "enable_indivisual_result_email",
                                e.target.checked
                              );
                            }}
                          />
                          <label
                            htmlFor="enable_indivisual_result_email"
                            className="form-check-label"
                          >
                            {t("E-Mail report per participant")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enable_pending_result_notification"
                            name="enable_pending_result_notification"
                            checked={values.enable_pending_result_notification}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enable_pending_result_notification"
                            className="form-check-label"
                          >
                            {t("Pending Result Notification")}
                          </label>
                        </div>
                        <div className="form-check mt-1 field_with_select">
                          <Field
                            type="checkbox"
                            id="auto_reminder_flag"
                            name="auto_reminder_configuration"
                            checked={values.auto_reminder_configuration}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="auto_reminder_flag1"
                            className="form-check-label"
                          >
                            {t("AUTO_REMINDER_IN")}{" "}
                            <Field
                              name={"auto_reminder_time_in_days"}
                              id="auto_reminder_day"
                              onBlur={handleBlur}
                              component={SelectField}
                              className="select_auto_reminder"
                              options={getDaysOptions()}
                              values={values.auto_reminder_time_in_days}
                              isDisabled={!values.auto_reminder_configuration}
                            />{" "}
                            {t("DAYS")}
                          </label>
                        </div>
                      </Grid>

                      <Grid item size={4}>
                        <h6>{t("General Features")}</h6>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enablePilot"
                            name="enablePilot"
                            checked={values.enablePilot}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enablePilot"
                            className="form-check-label"
                          >
                            {t("Pilot")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="isWebrtcEnable"
                            name="isWebrtcEnable"
                            checked={values.isWebrtcEnable}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="isWebrtcEnable"
                            className="form-check-label"
                          >
                            {t("Enable WebRTC")}
                          </label>
                        </div>
                        {/* // Added new flgs for IRN block feature (ISW) */}
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="block_irn_on_call_logs"
                            name="block_irn_on_call_logs"
                            checked={values.block_irn_on_call_logs}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="block_irn_on_call_logs"
                            className="form-check-label"
                          >
                            {t("Block IRN on Call logs")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="unblock_irn_after_nc_result"
                            name="unblock_irn_after_nc_result"
                            checked={values.unblock_irn_after_nc_result}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="unblock_irn_after_nc_result"
                            className="form-check-label"
                          >
                            {t("Unblock IRN after NC result")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="unblock_irn_after_dc_result"
                            name="unblock_irn_after_dc_result"
                            checked={values.unblock_irn_after_dc_result}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="unblock_irn_after_dc_result"
                            className="form-check-label"
                          >
                            {t("Unblock IRN after DC result")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="hide_deleted_participant"
                            name="hide_deleted_participant"
                            className="form-check-input"
                            checked={values.hide_deleted_participant}
                          />
                          <label
                            htmlFor="hide_deleted_participant"
                            className="form-check-label"
                          >
                            {t("Hide deleted participant")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="reverse_np_configuration"
                            name="reverse_np_configuration"
                            className="form-check-input"
                            checked={values.reverse_np_configuration}
                          />
                          <label
                            htmlFor="reverse_np_configuration"
                            className="form-check-label"
                          >
                            {t("REVERSE_NP_PARTICIPANT")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enable_pii_data_in_analytics"
                            name="enable_pii_data_in_analytics"
                            className="form-check-input"
                            checked={values.enable_pii_data_in_analytics}
                          />
                          <label
                            htmlFor="enable_pii_data_in_analytics"
                            className="form-check-label"
                          >
                            {t("ENABLE_PII_FLAG")}
                          </label>
                        </div>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Grid>
  );
};

export default InternalUseOnly;
