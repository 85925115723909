import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  filterJson,
  getlanguageIdFromcode,
  scrollTopWindow,
  sortByLabel,
} from "../../Helpers/index";
import {
  createProject,
  getAdditionalList,
  getAttribute,
  getBusinessLine,
  getCallCenterList,
  getProjectDetail,
  getResultConfigListData,
} from "../../Services/projectServices";
import { getlaguageAttribute } from "../../Services/tenantService";
import { getUserRolesPermissionsDefault } from "../../Services/userServices";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../components/Common";
import { Send_Api_Date_Format } from "../../config";
import i18n from "../../locale/i18n";
import PreventLeaveRoute from "../Utility/PreventLeaveRoute.js";
import AddAdditionConfiguration from "./AddAdditionConfiguration";
import AdditionConfiguration from "./AdditionConfiguration";
import BusinessLine from "./BusinessLine/index.js";
import { createDropdownOption } from "./Helper";
import ISW from "./ISW";
import InternalUseOnly from "./InternalUseOnly";
import ProjectInitialDetail from "./ProjectInitialDetail/index.js";

class CreateProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetail: {},
      departmentA: [],
      statusA: [],
      stageA: [],
      multilingualPhoneA: [],
      integrationMethodA: [],
      riskInternalA: [],
      riskExternalA: [],
      languageA: [],
      locationA: [],
      resultConfigurationTemplateOptions: [],
      acessCodeB: [],
      guideURLB: [],
      tagsA: [],
      interviewMethodA: [],
      projectTypeA: [],
      irnVerificationMethodsA: [],
      callCenterA: [],
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isinternal: [],
      internalFieldDisabled: true,
      userInfo: {},
      language: i18n.language,
      selectedFile: "", // to store selected file
      invalidImage: null, // handle the message of the image validation
      showConfigurationJson: "",
      ISWmsg: "",
      ISWmsg_error: "",
      Customer_apiurlError: false,
      configurationInitialValues: {
        participant_callback_url: "",
        result_callback_url: "",
        project_callback_url: "",
        participant_verification_url: "",
        security_type: "API_KEY",
        client_credential: {
          secret: "",
          audience: "",
          clientid: "",
          tokenuri: "auth0.com/token",
          authourization: "Bearer ",
          scope: "",
        },
      },
      keys: [],
      cloneProject: false,
      submitBtnToggle: false,
      languageTypeA: [],
      businessLineData: {},
      businessLineDataIntialkey: null,
      businessLineDataIntialLabel: null,
      currencyOptions: [],
    };
  }

  loadAttribute = () => {
    let self = this;
    let languagecode = i18n.language;
    let languageId = getlanguageIdFromcode(languagecode);
    getlaguageAttribute(languageId)
      .then((res) => {
        self.filterLanguageAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getAttribute(languageId)
      .then((res) => {
        self.filterAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkTimeDuration = (time) => {
    const currentDate = moment();
    const getTime = moment.unix(time / 1000).format("DD MMM YYYY hh:mm a");
    const diff = currentDate.diff(getTime, "minutes");
    return diff < 10;
  };

  getProjectCloneData = () => {
    const { search } = this.props.location;
    const checkTime = new URLSearchParams(search).has("_C");
    const checkId = new URLSearchParams(search).has("_ID");
    if (checkTime && checkId) {
      const getTime = new URLSearchParams(search).get("_C");
      const getId = new URLSearchParams(search).get("_ID");

      if (this.checkTimeDuration(getTime)) {
        getProjectDetail(getId)
          .then((res) => {
            let tagOptions = res.data.tags.map((option) => {
              return {
                value: option,
                label: option,
              };
            });
            let mergeTwoObject = {
              ...this.state.configurationInitialValues,
              ...res.data.clientAPIUrl1,
            };
            this.setState(
              {
                projectDetail: {
                  ...res.data,
                  name: res.data.name + " Copy",
                },
                configurationInitialValues: mergeTwoObject,
                tagsA: tagOptions,
                cloneProject: true,
                submitBtnToggle: true,
              },
              () => {
                scrollTopWindow();
              }
            );
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              alertMsg: err?.response?.data?.message,
              variant: "danger",
              alertStatus: true,
            });
          });
      } else {
        console.log(
          "The request is a clone to create a project but a timeout has occurred."
        );
        this.props.history.push("/main/project");
      }
    } else {
      console.log("The request is normal to create a project.");
    }
  };

  componentDidMount() {
    const self = this;
    this.loadAttribute();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    i18next.on("languageChanged", function (lng) {
      self.setState(
        {
          language: lng,
        },
        () => {
          self.loadAttribute();
        }
      );
    });

    getResultConfigListData()
      .then((res) => {
        console.log("getResultConfigListData res", res.data.template);
        this.setState({
          resultConfigurationTemplateOptions: res.data.template,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    getUserRolesPermissionsDefault()
      .then((res) => {
        if (
          res.data.role_permissions != undefined &&
          res.data.role_permissions.length > 0
        ) {
          res.data.role_permissions.forEach(function (obj) {
            internalPermissions = [...internalPermissions, ...obj.permissions];
          });
          this.setState({
            isinternal: internalPermissions,
            userInfo: userInfo,
          });
        }
      })
      .catch((err) => {
        console.log("user service err", err);
      });

    getCallCenterList()
      .then((res) => {
        let callCenterOptions = res.data
          .map((option) => {
            return {
              value: option.id,
              label: option.name,
            };
          })
          .sort(sortByLabel);
        this.setState({
          callCenterA: callCenterOptions,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getAdditionalList()
      .then((res) => {
        self.setState({
          keys: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertMsg: err?.response?.data?.message,
          variant: "danger",
          alertStatus: true,
        });
      });

    let internalPermissions = [];
    this.getProjectCloneData();
    this.getBusinessLineData();
  }

  shouldComponentUpdate() {
    return true;
  }

  getBusinessLineData = () => {
    getBusinessLine()
      .then((res) => {
        const intialKey = res.data[this.state.language]?.child;
        const intialLabel = res.data[this.state.language][intialKey]["label"];
        // console.log("Get Business Line Data res : ", intialKey, intialLabel);
        this.setState({
          businessLineData: res.data,
          businessLineDataIntialkey: intialKey,
          businessLineDataIntialLabel: intialLabel,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  filterAttribute = (res) => {
    let department = filterJson(res.data.attributes, "DEPARTMENT");
    let status = filterJson(res.data.attributes, "STATUS");
    let stage = filterJson(res.data.attributes, "STAGE");
    let multilingualPhone = filterJson(
      res.data.attributes,
      "MULTILINGUAL_PHONE"
    );
    let integrationMethod = filterJson(
      res.data.attributes,
      "INTEGRATION_METHOD"
    );
    let riskObjInternal = filterJson(
      res.data.attributes,
      "RISK_OBJECTIVE_INTERNAL"
    );
    let riskObjExternal = filterJson(
      res.data.attributes,
      "RISK_OBJECTIVE_EXTERNAL"
    );
    let language = filterJson(res.data.attributes, "LANGUAGE");
    let interviewMethods = filterJson(res.data.attributes, "INTERVIEW_METHOD");
    let projectType = filterJson(res.data.attributes, "TYPE");
    let irnVerificationMethods = filterJson(
      res.data.attributes,
      "IRN_Validation"
    );
    let locationOption = filterJson(res.data.attributes, "LOCATION");
    let acessCodeOption = filterJson(
      res.data.attributes,
      "ACCESS_CODE_BEHAVIOR"
    );
    let guideURLOption = filterJson(res.data.attributes, "GUIDE_URL");
    // console.log("GUIDE_URL", guideURLOption);
    this.setState({
      departmentA: department,
      statusA: status,
      stageA: stage,
      multilingualPhoneA: multilingualPhone,
      integrationMethodA: integrationMethod,
      riskInternalA: riskObjInternal,
      riskExternalA: riskObjExternal,
      languageA: language,
      locationA: locationOption,
      interviewMethodA: interviewMethods,
      projectTypeA: projectType,
      irnVerificationMethodsA: irnVerificationMethods,
      acessCodeB: acessCodeOption,
      guideURLB: guideURLOption,
    });
  };

  filterLanguageAttribute = (res) => {
    let languageType = filterJson(
      res.data.tenantAttributes,
      "PROJECT_LANGAUGE"
    );
    let currencyOptionsFilter = filterJson(
      res.data.tenantAttributes,
      "CURRENCY"
    );

    this.setState({
      languageTypeA: languageType,
      currencyOptions: currencyOptionsFilter,
    });
  };

  submitHandler = (field, resetForm, setSubmitting) => {
    let fields = { ...field };
    fields.estStartDate = fields.estStartDate
      ? moment(fields.estStartDate).format(Send_Api_Date_Format)
      : fields.estStartDate;
    fields.estEndDate = fields.estEndDate
      ? moment(fields.estEndDate).format(Send_Api_Date_Format)
      : fields.estEndDate;
    this.setState({
      submitBtnToggle: false,
    });
    let value = fields;

    if (value.tags) {
      value.tags = value.tags.map((item) => {
        return item.value;
      });
    } else {
      value.tags = [];
    }
    const { search } = this.props.location;
    const updateValue = {
      ...value,
      clone: this.state.cloneProject ? true : false,
      projectId: this.state.cloneProject
        ? new URLSearchParams(search).get("_ID")
        : null,
    };
    createProject(updateValue)
      .then((res) => {
        //call scrollTopWindow function for page scroll on top
        scrollTopWindow();
        this.setState(
          {
            alertMsg: this.props.t("Project Created Successfully"),
            variant: "success",
            alertStatus: true,
            projectDetail: res.data,
            cloneProject: false,
          },
          () => {
            resetForm();
          }
        );
        setSubmitting(false);
        setTimeout(() => {
          this.props.history.push("/main/project");
        }, 3000);
      })
      .catch((err) => {
        scrollTopWindow();
        this.setState({
          alertMsg: err?.response?.data?.message,
          variant: "danger",
          alertStatus: true,
          submitBtnToggle: true,
        });
        setSubmitting(false);
      });
  };
  cancelClick = () => {
    this.props.history.push(`/main/project`);
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  /* This funciton will set default language in project language */
  getEnglishLanguage = (languageOption) => {
    let languageTypeOption = languageOption
      .map((option) => {
        return createDropdownOption(option, true);
      })
      .sort(sortByLabel);

    const defaultLanguage = languageTypeOption.filter(
      (option) => option.label === "English"
    );
    return defaultLanguage;
  };

  render() {
    let { t } = this.props;
    const isSystemTenant = this.state?.userInfo?.tenant?.isSystemTenant;

    const businessLineSchema = Yup.object().shape({
      value: Yup.string().required(t("BUSINESS_VALIDATION_MESSAGE")),
    });
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const match = this.props.match;
    let self = this;
    let stageOption = this.state.stageA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    // Project Language Delivery dropdown Opion
    let multilingualPhoneOption = this.state.multilingualPhoneA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    let statusOption = this.state.statusA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);

    let language = this.state.languageA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);

    let acessCodeOption = this.state.acessCodeB
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);

    let guideURLOption = this.state.guideURLB
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);

    let interviewOption = this.state.interviewMethodA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);

    let irnVerificationMethodsOption = this.state.irnVerificationMethodsA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);

    // default Stage option
    let defaultStageOption = stageOption.filter(
      (option) => option.sequence === 1
    );
    // default language Delivery Option
    let deafultMultilingualPhoneOption = multilingualPhoneOption.filter(
      (option) => option.sequence === 2
    );
    // default Status option
    let defaultStatusOption = statusOption.filter(
      (option) => option.sequence === 1
    );
    let defaultLanguageOption = language.filter(
      (option) => option.sequence === 1
    );
    let defaultinterviewOption = interviewOption.filter(
      (option) => option.sequence === 5
    );

    let defaultacessCodeOption = acessCodeOption.filter(
      (option) => option.sequence === 1
    );

    let defaultguideURLOption = guideURLOption.filter(
      (option) => option.sequence === 1
    );

    let internalFieldRead =
      this.state.isinternal.indexOf("tenant.project.detail.internal.read") >= 0
        ? true
        : false;
    let internalFieldDisabled =
      this.state.isinternal.indexOf("tenant.project.detail.internal.update") >=
      0
        ? true
        : false;
    let internalFieldObj = {};
    if (internalFieldDisabled) {
      internalFieldObj = {
        deliveryMethodId: this.state.projectDetail.deliveryMethodId || "",
        irnVerificationMethodId: this.state.projectDetail
          .irnVerificationMethodId
          ? this.state.projectDetail.irnVerificationMethodId
          : irnVerificationMethodsOption.length > 0
          ? irnVerificationMethodsOption[0].value
          : "",
        call_delay_by_in_sec:
          this.state.projectDetail.call_delay_by_in_sec || "5",
        isIrnLength: internalFieldRead,
        irnLength: this.state.projectDetail.irnLength || "6",
        briefingQuestion: this.state.projectDetail.briefingQuestion || "",
        isRingCentral: this.state.projectDetail.isRingCentral || "",
        headsetQuestion: this.state.projectDetail.headsetQuestion || "",
        clientAPIUrl: this.state.projectDetail.clientAPIUrl || "",
        clientAPICredential: this.state.projectDetail.clientAPICredential || "",
      };
    }
    let initialValue = {
      creationDate: this.state.projectDetail.creationDate || "",
      startDate:
        this.state.projectDetail.startDate ||
        new Date().toLocaleDateString("en-US"),
      tenantId: this.state.userInfo.tenantId || "",
      name: this.state.projectDetail.name || "",
      resourcesPassword: this.state.projectDetail.resourcesPassword || "",
      description: self.state.projectDetail.description || "",
      note: this.state.projectDetail.note || "",
      languageId:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.languageId
          : language.length > 0
          ? defaultLanguageOption[0].value
          : "",
      parentId: this.state.projectDetail.parentId || "",
      locationId: this.state.projectDetail.locationId || "",
      departmentId: this.state.projectDetail.departmentId || "",
      typeId: this.state.projectDetail.typeId || "",
      externalRiskObjectiveId:
        this.state.projectDetail.externalRiskObjectiveId || "",
      noOfQuestions: this.state.projectDetail.noOfQuestions || "",
      tags: this.state.tagsA || [],
      estStartDate: this.state.projectDetail.estStartDate || "",
      estEndDate: this.state.projectDetail.estEndDate || "",
      estimatedInterviewTime:
        this.state.projectDetail.estimatedInterviewTime || "",
      closeDate: this.state.projectDetail.closeDate || "",
      estParticipant: this.state.projectDetail.estParticipant || "",
      questionaire_type: this.state.projectDetail.questionaire_type || "",
      result_configuration_template_id:
        this.state.projectDetail.result_configuration_template_id || "",
      sender_name: this.state.projectDetail.sender_name || "",
      result_email_recipient:
        this.state.projectDetail.result_email_recipient || "",
      statusId:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.statusId
          : statusOption.length > 0
          ? defaultStatusOption[0].value
          : "",
      stageId:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.stageId
          : stageOption.length > 0
          ? defaultStageOption[0].value
          : "",
      multilingualPhoneId:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.multilingualPhoneId
          : multilingualPhoneOption.length > 0
          ? deafultMultilingualPhoneOption[0].value
          : "",
      integration_method_id:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.integration_method_id
          : "",

      defaultDeliveryMethodId: 2,
      accountOwnerStatus: "",
      accessCodeBehaviorId:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.accessCodeBehaviorId
          : acessCodeOption.length > 0
          ? defaultacessCodeOption[0]?.value
          : "",
      guideURLOptionId:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.guideURLOptionId
          : guideURLOption.length > 0
          ? defaultguideURLOption[0]?.value
          : "",
      enableEmailCommunication:
        this.state.projectDetail.enableEmailCommunication || "",
      enable_refresh_accesscode:
        this.state.projectDetail.enable_refresh_accesscode || false,
      enable_indivisual_result_email:
        this.state.projectDetail.enable_indivisual_result_email || false,
      enableSMSCommunication:
        this.state.projectDetail.enableSMSCommunication || "",
      enablePilot: this.state.projectDetail.enablePilot || "",
      isWebrtcEnable: this.state.projectDetail.isWebrtcEnable || "",
      retake_restricted_24_hours:
        this.state.projectDetail.retake_restricted_24_hours || true,
      mask_overall_result_to_HR_for_AD:
        this.state.projectDetail.mask_overall_result_to_HR_for_AD || false,
      mask_overall_result_to_HR_for_CM:
        this.state.projectDetail.mask_overall_result_to_HR_for_CM || false,
      mask_overall_result_to_HR_for_NC:
        this.state.projectDetail.mask_overall_result_to_HR_for_NC || false,
      enable_pending_result_notification:
        this.state.projectDetail.enable_pending_result_notification || false,
      auto_reminder_time_in_days:
        this.state.projectDetail.auto_reminder_time_in_days || 3,
      auto_reminder_configuration:
        this.state.projectDetail.auto_reminder_configuration || false,
      allow_isw_create_participant:
        this.state.projectDetail.allow_isw_create_participant || false,
      allow_partial_nc_call_after_24_hours:
        this.state.projectDetail.allow_partial_nc_call_after_24_hours || false,
      make_partial_nc_as_complete_nc:
        this.state.projectDetail.make_partial_nc_as_complete_nc || false,
      review_unreview_configuration:
        this.state.projectDetail.review_unreview_configuration || false,
      allow_nc_call_after_24_hours:
        this.state.projectDetail.allow_nc_call_after_24_hours || false,
      invite_on_participant_create:
        this.state.projectDetail.invite_on_participant_create || "",
      sms_invite_on_participant_create:
        this.state.projectDetail.sms_invite_on_participant_create || "",
      block_irn_on_call_logs:
        this.state.projectDetail.block_irn_on_call_logs || true,
      unblock_irn_after_nc_result:
        this.state.projectDetail.unblock_irn_after_nc_result || true,
      unblock_irn_after_dc_result:
        this.state.projectDetail.unblock_irn_after_dc_result || true,
      hide_deleted_participant:
        this.state.projectDetail.hide_deleted_participant || false,
      reverse_np_configuration:
        this.state.projectDetail.reverse_np_configuration || false,
      cm_recordings_configuration:
        this.state.projectDetail.cm_recordings_configuration || false,
      riskObjectiveExternal: "test risk objective",
      defaultCustomerInterviewMethodId:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.defaultCustomerInterviewMethodId
          : interviewOption.length > 0
          ? defaultinterviewOption[0]?.value
          : "",
      result_auto_release:
        this.state.projectDetail.result_auto_release || false,
      clientAPIUrl1: this.state.cloneProject
        ? this.state.projectDetail.clientAPIUrl1
        : {
            participant_callback_url: "",
            result_callback_url: "",
            project_callback_url: "",
            participant_verification_url: "",
            caller_id_check: false,
            security_type: "API_KEY",
            client_credential: {
              secret: "",
              audience: "",
              clientid: "",
              tokenuri: "auth0.com/token",
              authourization: "Bearer ",
              scope: "",
            },
            multiLanguageId: this.state.projectDetail.multiLanguageId || "",
            additional_fields: [
              {
                key: {
                  value: "SMS_TEST_PHONE_NUMBER",
                  label: "SMS TEST PHONE NUMBER",
                },
                value: "",
              },
              {
                key: { value: "SMS_COUNTRY_CODE", label: "SMS COUNTRY CODE" },
                value: "+1",
              },
              {
                key: {
                  value: "PROJECT_LANGUAGES",
                  label: "PROJECT LANGUAGES",
                },
                value:
                  this.state.languageTypeA.length > 0
                    ? this.getEnglishLanguage(this.state.languageTypeA)
                    : "",
              },
            ],
          },
      currencyId:
        this.state.projectDetail.currencyId || userInfo?.currencyId || null,
      isBusinessLineOptional: !isSystemTenant ? true : false,
      enable_pii_data_in_analytics:
        this.state.projectDetail.enable_pii_data_in_analytics || false,
      //  businessLine: {},
      businessLine: [
        {
          value: "",
          label: "",
          currentFieldKey: this.state.businessLineDataIntialkey || "industry",
          currentFieldlabel:
            this.state.businessLineDataIntialLabel || "Industry",
        },
      ],
    };

    let finalInitialValue = { ...initialValue, ...internalFieldObj };

    Yup.addMethod(
      Yup.object,
      "requiredProperty",
      function (propertyPath, message) {
        return this.test("requiredProperty", message, function (value) {
          const { path, createError } = this;
          const propertyValue = propertyPath
            .split(".")
            .reduce(
              (acc, key) =>
                acc && acc[key] !== undefined ? acc[key] : undefined,
              value
            );
          if (
            !propertyValue ||
            (Array.isArray(propertyValue) && propertyValue.length === 0)
          ) {
            return createError({ path: `${path}.${propertyPath}`, message });
          }
          return true;
        });
      }
    );

    return (
      <React.Fragment>
        <div className="w-1200">
          <div>
            <div className="page-breadcrumb">
              {t("Projects")}
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "15px",
                  color: "#3e3f42",
                  fontWeight: 600,
                  marginRight: "5px",
                }}
              />
              <Link to={`${match.path}`}>{t("NEW_PROJECT")}</Link>
            </div>
            <div className="card">
              <div className="card-body">
                <Grid container>
                  <Grid item size={9}>
                    <h6 className="page-title"> {t("Create Project")}</h6>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <Formik
                  enableReinitialize
                  initialValues={finalInitialValue}
                  onSubmit={(fields, { resetForm, setSubmitting }) => {
                    this.submitHandler(fields, resetForm, setSubmitting);
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .max(
                        99,
                        t("Project name should be less than 100 character")
                      )
                      .required(t("Project Name is required")),
                    clientAPIUrl1: Yup.object({
                      additional_fields: Yup.array().of(
                        Yup.object().shape({
                          key: Yup.object()
                            .required(t("Please select the key"))
                            .nullable(),
                        })
                      ),
                    }),
                    enable_indivisual_result_email: Yup.boolean(),
                    sender_name: Yup.string().max(
                      30,
                      t("Email sender name should be less than 30 character")
                    ),
                    result_email_recipient: Yup.string().when(
                      "enable_indivisual_result_email",
                      {
                        is: true,
                        then: () =>
                          Yup.string()
                            .required(t("Emails are required"))
                            .test(
                              "valid-emails",
                              "Invalid email address(es)",
                              (value) => {
                                if (!value) {
                                  return true;
                                }
                                const emails = value.split(",");
                                const emailRegex =
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                return emails.every((email) =>
                                  emailRegex.test(email.trim())
                                );
                              }
                            ),
                        otherwise: () =>
                          Yup.string().test(
                            "valid-emails",
                            "Invalid email address(es)",
                            (value) => {
                              if (!value) {
                                return true;
                              }
                              const emails = value.split(",");
                              const emailRegex =
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                              return emails.every((email) =>
                                emailRegex.test(email.trim())
                              );
                            }
                          ),
                      }
                    ),
                    isIrnLength: Yup.boolean(),
                    irnLength: Yup.number().when("isIrnLength", {
                      is: true,
                      then: () =>
                        Yup.number()
                          .typeError(t("you must specify a number"))
                          .min(6, t("Min value 6"))
                          .max(15, t("Max value 15"))
                          .required(t("IRN Length is required")),
                    }),
                    isBusinessLineOptional: Yup.boolean(),
                    businessLine: Yup.array().when("isBusinessLineOptional", {
                      is: false,
                      then: () => Yup.array().of(businessLineSchema),
                      otherwise: () => Yup.array().notRequired(),
                    }),
                    tags: Yup.array().when(
                      ["businessLine", "isBusinessLineOptional"],
                      {
                        is: (businessLine, isBusinessLineOptional) =>
                          !isBusinessLineOptional &&
                          businessLine.some((line) => line.value === "other"),
                        then: () =>
                          Yup.array().min(1, t("BUSINESS_VALIDATION_MESSAGE")),
                        otherwise: () => Yup.array().notRequired(),
                      }
                    ),
                  })}
                >
                  {(prop) => {
                    const {
                      dirty,
                      values,
                      handleChange,
                      handleBlur,
                      isSubmitting,
                      handleReset,
                    } = prop;

                    return (
                      <Form>
                        <PreventLeaveRoute
                          reset={handleReset}
                          when={dirty}
                          // Navigate function
                          navigate={(field, historyObj) => {
                            historyObj.push(field);
                          }}
                          // Use as "message" prop of Prompt of React-Router
                          shouldBlockNavigation={(location) => {
                            if (location.pathname === "/") {
                              return false;
                            }
                            return !!dirty;
                          }}
                        />
                        <Field
                          name="tenantId"
                          id="tenantId"
                          type="hidden"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tenantId}
                        />
                        <Grid container>
                          <Grid item size={12}>
                            <AlertDismissible
                              msg={this.state.alertMsg}
                              variantType={this.state.variant}
                              show={this.state.alertStatus}
                              close={this.handleAlertClose}
                              autoClose
                            ></AlertDismissible>
                          </Grid>
                        </Grid>

                        {/* showing open accordian deatil  */}
                        <ProjectInitialDetail
                          formik={prop}
                          state={this.state}
                        />

                        <Grid container>
                          {internalFieldRead && this.state.businessLineData && (
                            <BusinessLine formik={prop} state={this.state} />
                          )}
                          {/* calling  InternalUseOnly component */}
                          {internalFieldRead && (
                            <InternalUseOnly formik={prop} state={this.state} />
                          )}
                          {/* calling  additional configuration component */}
                          <AdditionConfiguration
                            formik={prop}
                            state={this.state}
                          />

                          {/* calling  Add additional configuration component */}
                          {internalFieldRead && (
                            <AddAdditionConfiguration
                              formik={prop}
                              state={this.state}
                            />
                          )}

                          {/* calling ISW option component */}
                          {internalFieldRead && (
                            <ISW formik={prop} state={this.state} />
                          )}
                        </Grid>
                        <Grid container>
                          <Grid item size={12}>
                            <div className="form-group float-right mt-3">
                              <Button
                                variant="primary"
                                type="submit"
                                className={`rounded-0 mr-4 save-btn text-uppercase button_with_loader ${
                                  !this.state.Customer_apiurlError
                                    ? ""
                                    : "disabled disabled_link"
                                }`}
                                disabled={
                                  this.state.submitBtnToggle
                                    ? false
                                    : !dirty || isSubmitting
                                }
                              >
                                {isSubmitting ? (
                                  <>
                                    {t("Save")}...
                                    <div className="custom-loader linesLoader loader_inner_button">
                                      <Spinner color="#ffffff" size={"19px"} />
                                    </div>
                                  </>
                                ) : (
                                  <span>{t("Save")}</span>
                                )}
                              </Button>
                              <Button
                                variant="secondary"
                                type="button"
                                className="rounded-0 cancel-btn text-uppercase"
                                onClick={this.cancelClick}
                              >
                                {t("Cancel")}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectList: state.projects.projectList,
  };
};
export default withTranslation()(connect(mapStateToProps)(CreateProject));
